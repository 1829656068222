$metaHeight: 20px;
$metaHeightDesktop: 49px;

.page-header {
  position: relative;
}
@include media-breakpoint-up(md) {
  .subpage {
    .page-header {
      height: 750px;
    }
  }
}

/* Grid area definitions */

.page-header--logo {
  grid-area: logo;
}
.page-header--nav {
  grid-area: nav;
}
.page-header--widgets {
  grid-area: widgets;
}
.page-header--meta {
  grid-area: meta;
}

@include media-breakpoint-up(md) {

  .page-header {
    overflow: hidden;
  }

  /* 1st Level */

  .page-header--image {
    position: relative;
    z-index: 50;
  }
  .page-header--boxed {
    position: relative;
    z-index: 100;
  }
  .page-header--meta {
    position: relative;
    z-index: 100;
  }
  .page-header--cta {

  }


  /* Main Level */

  .page-header {
    height: 100vh;
    display: grid;
    grid-template-areas:
          'meta meta meta'
          '. logo-nav-widgets .';
    grid-template-columns: 1fr minmax(0, pxToVw($maxWidthDesktopPlus)) 1fr;
    grid-template-rows: $metaHeightDesktop auto;
    @include media-breakpoint-up(xlplus) {
      grid-template-columns: 1fr minmax(0, $maxWidthDesktopPlus) 1fr;
    }
  }

}