/* Version 2 */

@include media-breakpoint-down(sm) {
  .page-header--2 {
    &:after {
      content: '';
      width: 100%;
      height: pxToVwMobile(50px);
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
    }

    .page-header--logo .logo {
      background-image: svg-load('../_images/svg/logo-white.svg');
    }

    .page-header--contact a {
      color: $colorText;
    }

    .main-nav--burger {
      span {
        background: $colorText;

        &:before,
        &:after {
          background: $colorText;
        }
      }
    }

    .page-header--image {
      img {
        height: calc(100% - #{pxToVwMobile(50px)});
        top: auto;
        bottom: 0;
      }
      &:after {
        height: 100%;
      }
    }

  .page-header--widgets {
    padding: 0 pxToVwMobile(30px);
  }

  }
}


@include media-breakpoint-up(md) {
  .page-header--2  {
    &:after {
      content: '';
      width: 100%;
      height: 49px;
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 51;
    }
    .page-header--meta {
      border-bottom: 1px solid rgba($colorText, .2);

      .page-header--contact a,
      .page-header--social a,
      .page-header--language a {
        color: $colorText;
      }
    }
    .page-header--language li a {
      border-left: 1px solid rgba($colorText, .2);
    }

    .page-header--boxed {
      grid-template-areas:
              'logo nav'
              'widgets widgets';
    }
    .page-header--cta {
      position: absolute;
      bottom: 280px;
      h1 {
        width: 100%;
        text-align: center;
      }
    }



}

  @include media-breakpoint-between(md, lgl) {

    /* Page Header Boxed */

    .page-header--boxed {
      padding-bottom: 30px;
    }

    /* Booking Widget */

    $tabletPadding: 20px;
    .page-header--2 {
      .page-header--widgets {
        padding: 0;
      }
      .page-header--booking {
        max-width: 600px;
        margin: 0 auto;
        padding: 0;
        form {
          height: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          .booking-dates { grid-area: dates; }
          .booking-numbers { grid-area: numbers; }
          .booking-button { grid-area: button; }
          grid-template-areas:
                'dates dates button'
                'numbers numbers numbers';
        }
      }
      .booking-dates {
        @include fluid(grid-column-gap, 30px);
        grid-column-gap: $tabletPadding;
        padding: $tabletPadding;
      }
      .booking-button {
        border-bottom: 1px solid rgba(0,0,0,.1);
      }
      .booking-numbers {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
      .form-element.booking-element--numbers {
        display: block;
        border-right: 1px solid rgba(0, 0, 0, .1);
        padding: $tabletPadding;
        label {
          line-height: 14px;
          margin-bottom: 7px;
        }
      }
      .booking-button {
        padding-bottom: $tabletPadding;
        display: flex;
        .btn {
          align-self: flex-end;
          width: 100%;
        }
      }
    }
  }
}


@include media-breakpoint-up(xl) {

  /* Booking Widget */

  .page-header--2 {
    .page-header--booking {
      max-width: 1220px;
      margin: 0 auto;
      padding: 0;
      form {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3, auto);
      }
    }
    .booking-dates {
      border-bottom: none;
      border-right: 1px solid rgba(0,0,0,.1);
      grid-column-gap: 30px;
      padding: 30px;
    }
    .booking-numbers {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    .form-element.booking-element--numbers {
      display: block;
      border-right: 1px solid rgba(0,0,0,.1);
      padding: 30px 30px 0;
      label {
        line-height: 14px;
        margin-bottom: 7px;
      }
    }

    .booking-button {
      padding-bottom: 30px;
      display: flex;
      .btn {
        align-self: flex-end;
      }
    }
  }
}