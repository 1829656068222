/* Teaserslider Controls */

.teaserslider {
  position: relative;
}

@include media-breakpoint-down(md) {
  .teaserslider--controls {
    .prev,
    .next {
      position: absolute;
      top: pxToVwMobile(92px);
    }

    .prev {
      left: pxToVwMobile(12px);
    }

    .next {
      right: pxToVwMobile(12px);
    }

  }
}

@include media-breakpoint-up(md) {
  .teaserslider--controls {
    .prev,
    .next {
      position: absolute;
      top: 70px;
    }

    .prev {
      left: -20px;
    }

    .next {
      right: -20px;
    }
  }
}

@include media-breakpoint-up(lgl) {
  .teaserslider--controls {
    .prev,
    .next {
      position: absolute;
      top: 90px;
    }

    .prev {
      left: -20px;
    }

    .next {
      right: -20px;
    }
  }
}