.page-header--cta {
  width: 100%;
  align-self: flex-end;
  z-index: 1;
  text-align: center;
  padding-left: pxToVwMobile(30px);
  padding-right: pxToVwMobile(30px);
  white-space: nowrap;

  h1 {
    font-size: pxToVwMobile(45px);
    line-height: pxToVwMobile(40px);
    margin-bottom: pxToVwMobile(15px);
    font-weight: $font-weight-boldest;
    color: $white;
    white-space: normal;
    p {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .page-header--cta {
    padding: 0;
    h1 {
      @include fluidPlus(font-size, 80px);
      @include fluidPlus(line-height, 90px);
      @include fluidPlus(margin-bottom, 20px);
      @include fluidPlus(width, 750px);
      white-space: normal;
      text-align: left;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  /* Animations */

  .page-header--cta {
    & > * {
      &:nth-child(1) {
        animation: 1s ease-out 0s 1 animationOne;
      }
      &:nth-child(2) {
        animation: 1s ease-out 0s 1 animationTwo;
      }
      &:nth-child(3) {
        transition: transform ease-out 1s,
        background ease-in-out .2s,
        border-color ease-in-out .2s,
        color ease-in-out .2s;
        animation: 1s ease-out 0s 1 animationThree;
      }
    }
  }

  @keyframes animationOne {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes animationTwo {
    0% {
      opacity: 0;
      transform: translateX(75px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes animationThree {
    0% {
      opacity: 0;
      transform: translateX(125px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

}