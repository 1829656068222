/* General a-tag stylings */

$link-decoration: none;
$link-hover-decoration: none;

/********************
  Table
*********************/

$table-cell-padding: 10px;
$table-caption-color: $black;

/* Special Margins */

$headings-margin-bottom: 0;
$paragraph-margin-bottom: 15px;
$label-margin-bottom: 10px;

