@mixin fluidNav($property, $value) {
  #{$property}: $value;
  @include media-breakpoint-down(sm) {
    #{$property}: pxToVwMobile($value);
  }
}

.main-nav--toggle {
  display: none;
}

// mobile menu

@include media-breakpoint-down(xl) {

  /* Main Nav Top */

  .main-nav--top {
    @include fluidNav(height, 50px);
    @include fluidNav(width, 60px);
    overflow: hidden;
    z-index: 999999;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    @include hardware();
  }

  /* Mobile Menu Special */

  .mobile-menu-top {
    @include fluidNav(height, 50px);
    position: absolute;
    top: 0;
    @include fluidNav(right, 60px);
    width: 0;
    opacity: 0;
    white-space: nowrap;

    a {
      color: $white;
      @include fluidNav(line-height, $metaHeightDesktop);
      @include fluidNav(font-size, 14px);
      font-weight: $font-weight-base;
      padding: 0;
      display: inline-block;
      transition: opacity .15s ease-in-out;
      i {
        @include fluidNav(line-height, $metaHeightDesktop);
        @include fluidNav(margin-right, 5px);
        display: inline-block;
        float: left;
        &:before {
          @include fluid(font-size, 20px);
          display: inline-block;
          float: left;
        }
      }
    }
  }
  .mobile-menu--contact {
    display: inline-block;
    padding-left: pxToVwMobile(20px);
    position: absolute;
    left: 0;
    top: 0;
  }
  .mobile-menu--language {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;

    a {
      @include fluid(padding-left, 15px);
      @include fluid(padding-right, 15px);
      border-left: 1px solid rgba(255, 255, 255, .2);
    }
  }

  /* Main Nav Bottom */

  .mobile-menu-bottom {
    text-align: center;
    white-space: nowrap;
  }
  .mobile-menu-bottom--buttons {
    margin: 0;
    padding: 0;
    @include fluidNav(margin-top, 30px);
    @include fluidNav(margin-bottom, 30px);
    list-style-type: none;
    li {
      display: inline-block;
      @include fluidNav(padding-right, 7px);
      @include fluidNav(padding-left, 7px);
    }
  }
  .page-header--social {
    li {
      float: left;
      a {
        text-align: center;
        i {
          &:before {
            @include fluidNav(width, 46px);
          }
        }
      }
    }
  }

  // toggler & burger

  $burgerWidth: 30px;
  $burgerLineHeight: 2px;
  $burgerDistanceBetweenLines: 5px;
  $burgerPositionTop: 17px;
  $burgerPositionRight: 15px;
  $burgerHeight: ($burgerLineHeight * 3) + ($burgerDistanceBetweenLines * 2);

  $topBottomValue: ($burgerLineHeight + $burgerDistanceBetweenLines) * -1;

  .main-nav--burger {
    @include hardware();
    @include fluidNav(width, 60px);
    @include fluidNav(height, 50px);
    align-self: center;
    cursor: pointer;
    transition: all ease-in-out .15s;
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    place-items: center;
    border-left: 1px solid rgba(255, 255, 255, 0);

    z-index: 20001;

    span {
      display: block;
      @include fluidNav(width, $burgerWidth);
      @include fluidNav(height, $burgerLineHeight);
      background: $white;
      position: relative;
      transition: all ease-in-out .3s;
      &:before,
      &:after {
        content: '';
        display: block;
        @include fluidNav(width, $burgerWidth);
        @include fluidNav(height, $burgerLineHeight);
        min-height: $burgerLineHeight;
        background: $white;
        position: absolute;
        left: 0;
        transition: all ease-in-out .3s;
        opacity: 1;
      }


      &:before {
        @include fluidNav(top, $topBottomValue);
        transform-origin: center top;
      }

      &:after {
        @include fluidNav(bottom, $topBottomValue);
        transform-origin: center bottom;
      }
    }

  }

  // toggler checked


  .main-nav--toggle:checked {

    // burger
    & ~ .main-nav--top .main-nav--burger {
      transition: all ease-in-out .3s;

      border-left: 1px solid rgba(255, 255, 255, .2);

      span {
        background-color: transparent;

        &:before,
        &:after {
          background: $colorBackground;
        }

        &:before {
          transform: rotate(45deg) translateX(pxToVwMobile(10px));
          @include fluidNav(margin-left, -8px);
          @include media-breakpoint-up(md) {
            transform: rotate(45deg) translateX(10px);
          }
        }

        &:after {
          transform: rotate(-45deg) translateX(pxToVwMobile(10px));
          @include fluidNav(margin-left, -8px);
          @include media-breakpoint-up(md) {
            transform: rotate(-45deg) translateX(10px);
          }
        }
      }
    }

    // Main Nav Top
    & ~ .main-nav--top {
      background-color: $color1;
      position: fixed;
      @include fluidNav(width, 375px);
      border-bottom: 1px solid rgba(255, 255, 255, .2);
      transition: all .15s ease-in-out;

      .mobile-menu-top {
        transition: width ease-in-out .33s, opacity ease-in-out .5s;
        transition-delay: .15s;
        opacity: 1;
        width: calc(#{pxToVwMobile(375px)} - #{pxToVwMobile(60px)});
        @include media-breakpoint-up(md) {
          width: calc(375px - 60px);
        }
      }
    }

    // main-nav
    & ~ .main-nav {
      width: 100vw;
      opacity: 1;
      position: fixed;
      @include hardware();
      transition: all ease-in-out .15s;
      @include media-breakpoint-up(md) {
        width: 375px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
      }
    }

  }


  // main nav menu

  .main-nav {
    z-index: 20000;
    width: 0;
    height: 100vh;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    overflow-y: auto;
    background: $color1;
    @include fluidNav(top, 50px);
    //transition: all ease-in-out .15s;
    text-align: left;

    & > ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: block;
        position: relative;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
      }

      a,
      a.btn {
        display: block;
        color: $white;
        @include fluidNav(font-size, 18px);
        @include fluidNav(line-height, 21px);
        text-transform: uppercase;
        border: none;
        border-radius: 0;
        font-weight: $font-weight-bolder;
        @include fluidNav(padding-top, 24px);
        @include fluidNav(padding-right, 105px);
        @include fluidNav(padding-bottom, 24px);
        @include fluidNav(padding-left, 30px);
        transition: background-color .3s ease-in-out;

        &:hover {
          color: $white;
        }
      }

    }

    .main-nav--first-level {
      display: block;
    }

    // main nav sub menu

    .main-nav--sub {
      max-height: 0;
      overflow: hidden;
      transition: all ease-in-out .3s;
      background-color: transparent;
      border-top: none;
      padding-left: 0;

      li {
        border-bottom: none;
      }

      a {
        @include fluidNav(padding-top, 20px);
        @include fluidNav(padding-right, 30px);
        @include fluidNav(padding-bottom, 20px);
        @include fluidNav(padding-left, 80px);

        &:before {
          @include pseudo();
          @include css-arrow($white, right, pxToVwMobile(10px), pxToVwMobile(2px));
          @include media-breakpoint-up(md) {
            @include css-arrow($white, right, 10px, 2px);
          }
          position: absolute;
          @include fluidNav(left, 49px);
          @include fluidNav(top, 24px);

        }
      }
    }

  }

  // main nav dropdown icon

  .main-nav {
    .next {
      border: 2px solid rgba(255, 255, 255, .2);
      background: $color1;
      position: absolute;
      @include fluidNav(right, 30px);
      @include fluidNav(top, 12px);
      transition: border-color .15s ease-in-out;
    }

    .next--arrow {
      transition: transform .15s ease-in-out;
    }

    .dropdown {
      display: none;
    }

    .dropdown:checked {
      & ~ .next {
        border-color: $color1;

        .next--arrow {
          transform: rotate(135deg) translateX(-3px);
        }
      }

      & ~ a {
        background-color: $color1dark;
      }

      & ~ .main-nav--sub {
        max-height: 1000px;
        @include fluidNav(padding-bottom, 20px);
        background-color: $color1dark;
      }
    }
  }

}

// mixed variant between desktop and mobile (aka tablet)

@include media-breakpoint-between(md, xl) {
  .page-header--boxed {
    position: relative;
    z-index: 101;
  }
  .main-nav--top {
    height: 100px;
  }
  .main-nav--toggle:checked ~ .main-nav--top {
    height: 49px;

    .main-nav--burger {
      top: 0;
      right: 0;
    }
  }
  .main-nav--burger {
    top: 30px;
    right: 16px;
  }
  .main-nav {
    top: 49px;
    //width: 375px;
    right: -375px;
  }
  .main-nav--overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    background: rgba(0,0,0,1);
    top: 0;
    right: -100vw;
    opacity: 0;
    transition: opacity ease-in-out .5s;
  }
  .main-nav--toggle:checked {
    ~ .main-nav {
      right: 0;

    }
    ~ .main-nav--overlay {
      right: 0;
      opacity: .5;
    }
  }
  .mobile-menu--contact {
    padding-left: 20px;
  }
}

// standard menu

@include media-breakpoint-up(xll) {

  .mobile-menu-top,
  .mobile-menu-bottom {
    display: none;
  }

  // basics

  .main-nav {
    input {
      display: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    // special link (button)

    .btn {
      color: $white;
      padding-left: 20px;
      padding-right: 20px;
      background: none;
      border-color: $white;
      font-size: 13px;
      &:hover {
        color: $color1;
        background: $white;
        border-color: $white;
      }
    }

  }

  // first level

  ul.main-nav--first-level {
    li {
      display: inline-block;
      padding-left: 12px;
      padding-right: 12px;
      position: relative;
    }

    & > li:last-child {
      padding-right: 0;
    }

    a {
      display: block;
      line-height: 70px;
      color: $white;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: $font-weight-bolder;
      position: relative;
    }
  }


  // main nav sub


  ul.main-nav--sub {
    position: absolute;
    top: 100%;
    left: -15px;
    min-width: 180px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: $color1;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.08);
    text-align: left;
    z-index: 1;
    border-radius: $border-radius;

    li {
      display: block;
      position: relative;
      transition: background .33s ease-in-out;
      padding: 0;
      &.main-nav--sub-active,
      &:hover {
        background: $color1dark;
      }
    }

    a {
      display: block;
      line-height: 21px;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 30px;
      padding-right: 35px;
      font-weight: $font-weight-bolder;
      color: $white;
    }
  }

  // second level animation

  ul.main-nav--sub {
    transform: translateY(pxToVw(20px));
    opacity: 0;
    transition: all .33s ease-in-out;
    visibility: hidden;
  }
  ul.main-nav--first-level {
    li {
      &:hover,
      &:focus-within {
        & > ul.main-nav--sub {
          visibility: visible;
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  // special submenu position for last and 2nd to last main level items

  ul.main-nav--first-level {
    & > li {
      &:last-child,
      &:nth-last-child(2) {
        ul.main-nav--sub {
          left: auto;
          right: 15px;
        }
      }
    }
  }

  // plusminus

  $triangleDimension: 7px;
  .main-nav {
    .main-nav--first-level {
      & > li {
        & > .plusminus {
          display: none;
        }

        .next {
          width: unset;
          height: unset;
          border-radius: unset;
          display: block;
          background: none;

          .next--arrow {
            border-width: unset;
            border-color: unset;
            border-style: unset;
            margin: 0;
            @include css-triangle($color1, up, $triangleDimension);
            top: calc(100% - #{$triangleDimension});
            left: 50%;
            transform: translateY(pxToVw(20px)) translateX(-50%);
            opacity: 0;
            transition: all .33s ease-in-out;
          }
        }

        &:hover,
        &:focus-within {
          .next--arrow {
            transform: translateY(0) translateX(-50%);
            opacity: 1;
          }
        }

      }
    }
  }

}
@include media-breakpoint-up(xll) {
  .main-nav .main-nav--first-level {
    a {
      display: block;
      position: relative;
    }
    a::after {
      content: '';
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: $color1;
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }

    a > :hover::after,
    a > :focus::after {
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
    }

    li > a::after {
      opacity: 1;
      transform: scale(0);
      transform-origin: center;
    }

    li > a:hover::after,
    li > a:focus::after{
      transform: scale(1);
    }

    .desktop-down-hidden {
      a::after {
        display: none;
      }
    }

    .main-nav--sub {
      a::after {
        display: none;
      }
    }
  }
}

