/* Fixed Navigation */

/* All */

.page-header--nav {
  &:before {
    content: '';
    width: 100vw;
    background: $white;
    position: fixed;
    left: 0;
    z-index: 9999;
    box-shadow: 0 0 60px rgba(0, 0, 0, .1);
    transition: opacity ease-in-out .375s, transform ease-in-out .375s;
    //will-change: opacity, transform;
    top: 0;
    opacity: 0;
  }
}
.main-nav--fixed {
  .page-header--nav {
    &:before {
      transform: translateY(0);
      transition-delay: 0s;
      opacity: 1;
    }
  }
}

/* Mobile */

@include media-breakpoint-down(sm) {
  ul.main-nav--first-level > li > a {
    transition: color ease-in-out .175s;
  }
  .main-nav--burger {
    top: 1px;
  }
  .page-header--nav {
    &:before {
      height: pxToVwMobile(50px);
      //top: pxToVwMobile(-50px);
      transform: translateY(pxToVwMobile(-50px));
    }
  }

  .main-nav--fixed {
    .main-nav--burger {
      position: fixed;
      span {
        background: $colorText;
        &:before,
        &:after {
          background: $colorText;
        }
      }
    }
  }

}

/* Tablet & Desktop */

@include media-breakpoint-up(md) {
  .main-nav {
    position: relative;
    z-index: 10000;
    .btn {
      transition: all ease-in-out .175s;
    }
  }
  ul.main-nav--first-level > li > a {
    transition: color ease-in-out .175s;
  }

  .page-header--nav {
    position: relative;
  }
  .main-nav--fixed {
    .main-nav {
      position: fixed;
      top: 0;
      left: 0;
      ul.main-nav--first-level {
        max-width: map-get($grid-breakpoints, xlplus);
        margin: 0 auto;
      }
    }
  }
}

/* Tablet */

@include media-breakpoint-between(md, xl) {
  .main-nav--top {
    height: 50px;
    overflow: visible;
  }
  .main-nav--burger {
    top: 0;
    right: 0;
    transition: none;
  }
  .page-header--nav {
    &:before {
      height: 50px;
      //top: -50px;
      transform: translateY(-50px);
    }
  }
  .main-nav--fixed {
    .main-nav--toggle:checked ~ .main-nav--top,
    .main-nav--top {
      position: fixed;
    }

    .main-nav--burger {
      top: 0;
      right: 30px;

      span {
        background: $colorText;

        &:before,
        &:after {
          background: $colorText;
        }
      }
    }

    .main-nav {
      left: auto;
      top: 49px;
    }

    .main-nav--toggle:checked ~ .main-nav--top {
      .main-nav--top {
        .main-nav--burger {
          span {
            color: transparent;
          }
        }
      }
    }
  }
}

/* Desktop */

@include media-breakpoint-up(xll) {
  .page-header--nav {
    &:before {
      height: 70px;
      transform: translateY(-70px);
    }
  }
  .main-nav--fixed {
    .page-header--nav {
      transition: all ease-in-out .375s;
    }

    .main-nav {
      width: 100%;
      transition: all ease-in-out .33s;

      ul.main-nav--first-level {
        padding: 0 30px;

        & > li > a {
          color: $colorText;
        }
      }

      .btn {
        border-color: $colorText;
      }
    }
  }
}