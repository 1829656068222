@import 'variables';
@import 'mixins';

:root {
  --grid-tablet: 768;
  --grid-tablet-small: 992;
  --grid-desktop: 1400;
}

iframe {
  overflow: hidden;
}
/* Variables calculated after both files */



/* libraries */
@import '../.build/node_modules/@splidejs/splide/dist/css/splide.min';
@import '../.build/node_modules/baguettebox.js/src/baguetteBox';
@import '../.build/node_modules/flatpickr/dist/flatpickr';

/* basics */
@import 'reboot';
@import 'fonts';
@import 'icons';
@import 'typography';
@import 'helpers';
@import 'base';
@import "project_customizations"; /* Contains Customizations for the project */


/* elements */
@import 'elements/datepicker';
@import 'elements/form';
@import 'elements/buttons';
@import 'elements/teaser';
@import 'elements/teaser--small';
@import 'elements/teaserslider-controls';
@import 'elements/logos';

/* page-header */
@import 'page-header/page-header';

@import 'page-header/page-header--meta';
@import 'page-header/page-header--boxed';
@import 'page-header/page-header--cta';
@import 'page-header/page-header--image';

/* page-header widgets */
@import 'page-header/page-header--teaserslider';
@import 'page-header/page-header--smallteasers';
@import 'page-header/page-header--booking';
@import 'page-header/page-header--nav';
@import 'page-header/page-header--nav-fixed';

/* page-header versions */
@import 'page-header/page-header--versions';

/* page-footer */
@import 'page-footer/footer';

/* content-elements elements */
@import 'content-elements/headlines';
@import 'content-elements/accordion';
@import 'content-elements/largeteaser';
@import 'content-elements/teaserslider';
@import 'content-elements/textbox';
@import 'content-elements/wysiwyg';
@import 'content-elements/gallery';
@import 'content-elements/table';
@import 'content-elements/grid';
@import 'content-elements/divider';
@import 'content-elements/disruptor';

@include media-breakpoint-down(sm) {
  .mobile-hidden {
    display: none !important;
  }
}
@include media-breakpoint-down(xl) {
  .desktop-down-hidden {
    display: none !important;
  }
}

// general background for videos

video {
  background-color: $color1dark;
}

// todo: move to own file
.contentblock--image {
  img {
    width: 100%;
    height: auto;
  }
  .image-video {
    width: 100%;
    position: relative;

    video {
      /*position: absolute;
      top: 0;
      left: 0;*/
      width: 100%;
      height: auto;
    }
  }
}

/* Contact Map */


.contact_map {
  height: 50vh;
  z-index: 1;
  overflow: hidden;
}

/* Alert */

.alert {
  @include fluid(padding, 30px);
  @include fluid(border-width, 2px);
  border-style: solid;
  border-color: $color1;
  @include fluid(border-radius, 20px);
  background: rgba($color1, 0.2);
  color: $color1;
  text-align: center;
  @include fluid(margin-bottom, 30px);
}

/* Preload */

.preload * {
  transition: none !important;
}

body {
  overflow-x: hidden;
}

/* Set border-radius to 0 if body has class 'no-rounded-borders' (set via property) */

.no-rounded-borders {
  ul.main-nav--sub,
  .largeteaser--content-container,
  .contentblock .teaser,
  .teaser,
  .btn,
  .prev, .next,
  input[type=email],
  input[type=text],
  input[type=date],
  input[type=password],
  select,
  textarea,
  .form-column button,
  .page-footer--address {
    @include fluid(border-radius, 0);
  }
}


