$font-path: '/static/fonts';

/* roboto-regular - latin */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Roboto'), local('Roboto-Regular'),
  url('#{$font-path}/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('#{$font-path}/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: local('Roboto Black'), local('Roboto-Black'),
  url('#{$font-path}/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/roboto-v20-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* ---------------------------------------------------------------- */

/* Iconfont */

$icomoon-font-family: "iconfont" !default;
$icomoon-font-path: $font-path !default;

$iconfont-arrow-right: "\e900";
$iconfont-calendar: "\e901";
$iconfont-envelope: "\e902";
$iconfont-facebook: "\e903";
$iconfont-instagram: "\e904";;
$iconfont-phone: "\e905";
$iconfont-pinterest: "\e906";
$iconfont-twitter: "\e907";
$iconfont-youtube: "\e908";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?a9ohi6') format('truetype'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?a9ohi6') format('woff'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?a9ohi6##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconfont-"], [class*=" iconfont-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont-arrow-right {
  &:before {
    content: $iconfont-arrow-right;
  }
}
.iconfont-calendar {
  &:before {
    content: $iconfont-calendar;
  }
}
.iconfont-envelope {
  &:before {
    content: $iconfont-envelope;
  }
}
.iconfont-facebook {
  &:before {
    content: $iconfont-facebook;
  }
}
.iconfont-instagram {
  &:before {
    content: $iconfont-instagram;
  }
}
.iconfont-phone {
  &:before {
    content: $iconfont-phone;
  }
}
.iconfont-pinterest {
  &:before {
    content: $iconfont-pinterest;
  }
}
.iconfont-twitter {
  &:before {
    content: $iconfont-twitter;
  }
}
.iconfont-youtube {
  &:before {
    content: $iconfont-youtube;
  }
}