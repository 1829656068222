/* Main Button */

$btn-line-height: $btn-height - (2 * $btn-border-width);
$btn-line-height-flat: $btn-alt-height - (2 * $btn-border-width);

.btn {

  display: inline-block;
  background: $btn-main-color-bg;
  color: $btn-main-color-txt;
  border-color: $btn-main-color-border;

  border-style: $btn-border-style;
  @include fluid(border-width, $btn-border-width);

  text-transform: $btn-text-transform;
  font-weight: $btn-font-weight;
  @include fluidDiff(line-height, $btn-line-height-flat, $btn-line-height);
  @include fluidDiff(font-size, $btn-font-size-mobile, $btn-font-size-desktop);

  @include fluid(border-radius, $btn-border-radius);

  @include fluidDiff(padding-left, $btn-padding-mobile, $btn-padding-desktop);
  @include fluidDiff(padding-right, $btn-padding-mobile, $btn-padding-desktop);
  transition:
          box-shadow ease-in-out .2s,
          background ease-in-out .2s,
          border-color ease-in-out .2s,
          color ease-in-out .2s;

  &:hover {
    background: $btn-main-color-bg-hover;
    color: $btn-main-color-txt-hover;
    border-color: $btn-main-color-border-hover;
  }

  // flat

  &.btn--flat {
    @include fluidDiff(line-height, $btn-line-height-flat, $btn-line-height-flat);
  }

  // outline

  &.btn--outline {
    background: $btn-out-color-bg;
    border-color: $btn-out-color-txt;
    color: $btn-out-color-border;
    box-shadow: 0 0 60px rgba(0,0,0,0);

    &:hover {
      background: $btn-out-color-bg-hover;
      color: $btn-out-color-txt-hover;
      border-color: $btn-out-color-border-hover;
      box-shadow: 0 0 60px rgba(0,0,0,0.1);
    }

  }

  // outline negative

  &.btn--outline-negative {
    background: $btn-neg-color-bg;
    color: $btn-neg-color-txt;
    border-color: $btn-neg-color-border;

    @include media-breakpoint-up(xll) {
      &:hover {
        background: $btn-neg-color-bg-hover;
        color: $btn-neg-color-txt-hover;
        border-color: $btn-neg-color-border-hover;
      }
    }

  }

  &.btn--outline-negative--variant {
    background: $btn-negvar-color-bg;
    color: $btn-negvar-color-txt;
    border-color: $btn-negvar-color-border;
  }

}

// arrow-link

a {
  &.arrow-link {
    color: $al-color-txt;
    i {
      @include fluid(font-size, 12px);
      @include fluid(margin-right, 15px);
      color: $al-color-border;
      transition: color ease-in-out .3s;
    }
    &:hover {
      i {
        color: $al-color-hover-icon;
        opacity: 1;
      }
    }
  }
}

// arrows

.prev, .next {
  display: inline-grid;
  @include fluid(width, $navbtn-size);
  @include fluid(height, $navbtn-size);
  @include fluid(border-radius, $navbtn-border-radius);
  background: $navbtn-color-bg;
  cursor: pointer;
  place-items: center;
}
$margin-left-value: $navbtn-arrow-size/3.3333;
.prev--arrow {
  @include css-arrow($navbtn-arrow-color, left, pxToVwMobile($navbtn-arrow-size), pxToVwMobile($navbtn-arrow-width));
  @include fluid(margin-left, $margin-left-value);
  @include media-breakpoint-up(md) {
    @include css-arrow($navbtn-arrow-color, left, $navbtn-arrow-size, $navbtn-arrow-width);
  }
}
.next--arrow {
  @include css-arrow($navbtn-arrow-color, right, pxToVwMobile($navbtn-arrow-size), pxToVwMobile($navbtn-arrow-width));
  @include fluid(margin-left, $margin-left-value * -1);
  @include media-breakpoint-up(md) {
    @include css-arrow($navbtn-arrow-color, right, $navbtn-arrow-size, $navbtn-arrow-width);
  }
}