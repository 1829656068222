@include media-breakpoint-down(sm) {

  .contentblock--teaserslider {

    /*padding: 0;*/
    margin-top: pxToVwMobile(-30px);

    .container {
      padding: 0;
    }

    /*padding-left: pxToVwMobile(15px);
    padding-right: pxToVwMobile(15px);*/

    position: relative;
    .teaser {
      //box-shadow: none;
    }

    .splide__track {
      padding-top: pxToVwMobile(30px);
      padding-bottom: pxToVwMobile(30px);
    }

  }
}
@include media-breakpoint-up(md) {



  .contentblock--teaserslider {
    .splide__track {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .teaser {
      max-width: unset;
      height: 100%;
      //max-width: 370px;
    }
    .box-shadow {
      box-shadow: 0 0 20px rgba(0,0,0,.075);
    }
    .teaserslider--controls {
      .prev,
      .next {
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      }
      .prev {
        //left: -22.5px;
        left: 0;
      }
      .next {
        //right: -22.5px;
        right: 0;
      }
    }
  }

  /*.contentblock--teaserslider {
    .tns-ovh {
      overflow: visible;
    }
    .teaserslider--container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include fluid(grid-column-gap, 30px);
      grid-column-gap: 30px;
    }
    .teaserslider--controls {
      display: none;
    }
  }*/
}

@include media-breakpoint-up(xl) {

}