.contentblock--divider {
  hr {
    border: none;
    height: 1px;
    background-color: rgba(0,0,0,.1);
  }
}
.contentblock {
  &.contentblock--divider {
    @include fluid(margin-bottom, 90px);
  }
}