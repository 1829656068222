.page-header--smallteasers {
  display: grid;
  @include fluid(grid-row-gap, 10px);
  .teaser--small {
    h3 {
      font-weight: $font-weight-boldest;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-left: pxToVwMobile(30px);
    margin-right: pxToVwMobile(30px);
  }
  @include media-breakpoint-up(md) {
    align-self: flex-end;
    max-width: 360px;
  }
}
