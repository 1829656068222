.contentblock {

  h1, h2, h3, h4, h5 {
    font-weight: $font-weight-boldest;
  }

  h1 {
    @include fluidDiff(font-size, 39px, 48px);
    @include fluidDiff(line-height, 46px, 56px);
  }
  h2 {
    @include fluidDiff(font-size, 39px, 39px);
    @include fluidDiff(line-height, 46px, 46px);
  }
  h3 {
    @include fluidDiff(font-size, 31px, 31px);
    @include fluidDiff(line-height, 36px, 36px);
  }
  h4 {
    @include fluidDiff(font-size, 25px, 25px);
    @include fluidDiff(line-height, 29px, 29px);
  }
  h5 {
    @include fluidDiff(font-size, 20px, 20px);
    @include fluidDiff(line-height, 23px, 23px);
  }

}