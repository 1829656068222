.gallery--container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.gallery--image {
  display: block;
  img {
    width: 100%;
    height: auto;
  }
}

.gallery--container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
.contentblock--gallery-large {
  .gallery--container {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

@include media-breakpoint-up(md) {
  .gallery--container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: pxToVw(30px);
    & > a {
      flex-grow: 1;
      width: calc(25% - 2em);
      max-width: calc(25% - 2em);
    }
  }
}

@include media-breakpoint-up(lg) {
  .gallery--container {
    gap: 30px;
  }
}