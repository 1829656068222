.contentblock--wysiwyg {

  p {
    @include fluidDiff(font-size, 15px, 16px);
    @include fluidDiff(line-height, 23px, 25px);
  }

  a {
    color: $black;
    text-decoration: underline;
    font-weight: $font-weight-bolder;
    &[target="_blank"] {
      font-weight: $font-weight-base;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      padding-left: 20px;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        background: $color1;
        position: absolute;
        left: 0;
        top: 9px;
      }
    }
  }


}
.contentblock--wysiwyg-lead {
  p {
    @include fluidDiff(font-size, 17px, 19px);
    @include fluidDiff(line-height, 23px, 26px);
    font-weight: $font-weight-bolder;
  }
}

