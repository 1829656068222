@charset "UTF-8";
/* General Border Radius */
/* Fonts */
/* Colors */
/* Conecto Modal Variables */
:root {
  --cm-border-color: #69BFAC;
  --cm-font-headline: 'Arial';
  --cm-font-text: 'Times New Roman'; }

/********************
  Buttons
*********************/
/* General - for all buttons */
/* Main Button */
/* Outline Button */
/* Negative Outline Button */
/* Negative Outline Button - Variant */
/********************
  Arrow Links
*********************/
/********************
  Navigational Buttons (.prev, .next)
*********************/
/* General a-tag stylings */
/********************
  Table
*********************/
/* Special Margins */
/* Breakpoints */
/* Page Layout Elements */
/* Container Paddings & Margins */
/* Breakpoints */
/* Units Stripping */
/* Fluid for mobile (375px) */
/* Fluid up to xl */
/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */
/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */
/* Fluid up to xlplus */
/* Fluid up to outer */
/* Icons */
:root {
  --grid-tablet: 768;
  --grid-tablet-small: 992;
  --grid-desktop: 1400; }

iframe {
  overflow: hidden; }

/* Variables calculated after both files */
/* libraries */
@keyframes splide-loading {
  0% {
    transform: rotate(0); }
  to {
    transform: rotate(1turn); } }

.splide__container {
  position: relative;
  box-sizing: border-box; }

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: -webkit-max-content;
  width: max-content;
  will-change: transform; }

.splide.is-active .splide__list {
  display: flex; }

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0; }

.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0; }

.splide {
  visibility: hidden; }

.splide, .splide__slide {
  position: relative;
  outline: none; }

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0; }

.splide__slide img {
  vertical-align: bottom; }

.splide__slider {
  position: relative; }

.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #999;
  border-left-color: transparent;
  animation: splide-loading 1s linear infinite; }

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden; }

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none; }

.splide--fade > .splide__track > .splide__list {
  display: block; }

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0; }

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1; }

.splide--rtl {
  direction: rtl; }

.splide--ttb > .splide__track > .splide__list {
  display: block; }

.splide--ttb > .splide__pagination {
  width: auto; }

.splide__arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  opacity: .7;
  background: #ccc; }

.splide__arrow svg {
  width: 1.2em;
  height: 1.2em; }

.splide__arrow:hover {
  cursor: pointer;
  opacity: .9; }

.splide__arrow:focus {
  outline: none; }

.splide__arrow--prev {
  left: 1em; }

.splide__arrow--prev svg {
  transform: scaleX(-1); }

.splide__arrow--next {
  right: 1em; }

.splide__pagination {
  position: absolute;
  z-index: 1;
  bottom: .5em;
  left: 50%;
  transform: translateX(-50%);
  padding: 0; }

.splide__pagination__page {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ccc;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transform .2s linear;
  border: none;
  opacity: .7; }

.splide__pagination__page.is-active {
  transform: scale(1.4);
  background: #fff; }

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9; }

.splide__pagination__page:focus {
  outline: none; }

.splide__progress__bar {
  width: 0;
  height: 3px;
  background: #ccc; }

.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid transparent; }

.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: #000; }

.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none; }

.splide--rtl > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  right: 1em;
  left: auto; }

.splide--rtl > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1); }

.splide--rtl > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto; }

.splide--rtl > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1); }

.splide--ttb > .splide__arrows .splide__arrow, .splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%); }

.splide--ttb > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em; }

.splide--ttb > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: rotate(-90deg); }

.splide--ttb > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  top: auto;
  bottom: 1em; }

.splide--ttb > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: rotate(90deg); }

.splide--ttb > .splide__pagination {
  display: flex;
  flex-direction: column;
  bottom: 50%;
  left: auto;
  right: .5em;
  transform: translateY(50%); }

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease; }
  #baguetteBox-overlay.visible {
    opacity: 1; }
  #baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center; }
    #baguetteBox-overlay .full-image figure {
      display: inline;
      margin: 0;
      height: 100%; }
    #baguetteBox-overlay .full-image img {
      display: inline-block;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }
    #baguetteBox-overlay .full-image figcaption {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      line-height: 1.8;
      white-space: normal;
      color: #ccc;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.6);
      font-family: sans-serif; }
    #baguetteBox-overlay .full-image:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease; }
  #baguetteBox-slider.bounce-from-right {
    animation: bounceFromRight .4s ease-out; }
  #baguetteBox-slider.bounce-from-left {
    animation: bounceFromLeft .4s ease-out; }

@keyframes bounceFromRight {
  0% {
    margin-left: 0; }
  50% {
    margin-left: -30px; }
  100% {
    margin-left: 0; } }

@keyframes bounceFromLeft {
  0% {
    margin-left: 0; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0; } }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px; }

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease; }
  .baguetteBox-button:focus, .baguetteBox-button:hover {
    background-color: rgba(50, 50, 50, 0.9); }
  .baguetteBox-button#next-button {
    right: 2%; }
  .baguetteBox-button#previous-button {
    left: 2%; }
  .baguetteBox-button#close-button {
    top: 20px;
    right: 2%;
    right: calc(2% + 6px);
    width: 30px;
    height: 30px; }
  .baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0; }

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px; }

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out; }

.baguetteBox-double-bounce2 {
  animation-delay: -1s; }

@keyframes bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

/* basics */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #333333;
  text-align: left;
  background-color: white; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 15px; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 500; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #6E110C;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #6E110C;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin: 0; }

button {
  border-radius: 0; }

button:focus {
  /*outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;*/
  outline: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local("Roboto"), local("Roboto-Regular"), url("/static/fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("/static/fonts/roboto-v20-latin-regular.woff") format("woff"), url("/static/fonts/roboto-v20-latin-regular.ttf") format("truetype"), url("/static/fonts/roboto-v20-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/static/fonts/roboto-v20-latin-500.woff2") format("woff2"), url("/static/fonts/roboto-v20-latin-500.woff") format("woff"), url("/static/fonts/roboto-v20-latin-500.ttf") format("truetype"), url("/static/fonts/roboto-v20-latin-500.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: local("Roboto Black"), local("Roboto-Black"), url("/static/fonts/roboto-v20-latin-900.woff2") format("woff2"), url("/static/fonts/roboto-v20-latin-900.woff") format("woff"), url("/static/fonts/roboto-v20-latin-900.ttf") format("truetype"), url("/static/fonts/roboto-v20-latin-900.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* ---------------------------------------------------------------- */
/* Iconfont */
@font-face {
  font-family: "iconfont";
  src: url("/static/fonts/iconfont.ttf?a9ohi6") format("truetype"), url("/static/fonts/iconfont.woff?a9ohi6") format("woff"), url("/static/fonts/iconfont.svg?a9ohi6#iconfont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="iconfont-"], [class*=" iconfont-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.iconfont-arrow-right:before {
  content: ""; }

.iconfont-calendar:before {
  content: ""; }

.iconfont-envelope:before {
  content: ""; }

.iconfont-facebook:before {
  content: ""; }

.iconfont-instagram:before {
  content: ""; }

.iconfont-phone:before {
  content: ""; }

.iconfont-pinterest:before {
  content: ""; }

.iconfont-twitter:before {
  content: ""; }

.iconfont-youtube:before {
  content: ""; }

.icon,
.inline-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1em; }

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }

.page-footer h5 {
  font-size: 4.26667vw;
  line-height: 5.06667vw;
  margin-bottom: 5.33333vw; }
  @media (min-width: 768px) {
    .page-footer h5 {
      font-size: 20px; } }
  @media (min-width: 768px) {
    .page-footer h5 {
      line-height: 23px; } }
  @media (min-width: 768px) {
    .page-footer h5 {
      margin-bottom: 30px; } }

/* Basic Visibility Classes */
@media (max-width: 767.98px) {
  .desktop-only {
    display: none !important; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.box-shadow {
  box-shadow: 0 0 8vw rgba(0, 0, 0, 0.1); }
  @media (min-width: 768px) {
    .box-shadow {
      box-shadow: 0 0 5vw rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1200px) {
    .box-shadow {
      box-shadow: 0 0 60px rgba(0, 0, 0, 0.1); } }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

/* Selection */
::selection {
  background: #6E110C;
  color: white; }

/* Basic Containers */
html {
  scroll-behavior: smooth; }

body {
  background: #f2f2f2;
  font-size: 4vw;
  line-height: 6.13333vw; }
  @media (min-width: 768px) {
    body {
      font-size: 16px; } }
  @media (min-width: 768px) {
    body {
      line-height: 25px; } }

.page-wrap {
  margin: 0 auto;
  background: white; }

main[role=main] {
  padding-top: 16vw;
  background-color: #f2f2f2; }
  @media (min-width: 768px) {
    main[role=main] {
      padding-top: 100px; } }
  @media (min-width: 768px) {
    main[role=main] {
      padding-bottom: 55px; } }

/* Container */
/* Content widths according to BB:

  - narrow (960 - 1036px - mean value 998px which corresponds to 992px from bootstrap widths)
  - normal (1570px)
  - wide (100%)

  2, 3 and 4 columns are possible

 */
.container {
  max-width: 1630px;
  margin: 0 auto;
  padding-left: 4vw;
  padding-right: 4vw; }
  @media (min-width: 768px) {
    .container {
      padding-left: 30px; } }
  @media (min-width: 768px) {
    .container {
      padding-right: 30px; } }

@media (min-width: 768px) {
  .container--narrow {
    max-width: 992px; }
  .container--wide {
    max-width: 100%;
    padding-left: 4vw;
    padding-right: 4vw; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .container--wide {
      padding-left: 0; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .container--wide {
      padding-right: 0; } }

/* Columns */
.cols .container {
  padding-left: 0vw;
  padding-right: 0vw; }
  @media (min-width: 768px) {
    .cols .container {
      padding-left: 0; } }
  @media (min-width: 768px) {
    .cols .container {
      padding-right: 0; } }

@media (max-width: 879.98px) {
  .cols .col {
    margin-bottom: 8vw; } }

@media (min-width: 768px) {
  .cols .contentblock.margin-bottom-tiny, .cols .contentblock.margin-bottom-small, .cols .contentblock.margin-bottom-medium, .cols .contentblock.margin-bottom-large, .cols .contentblock.margin-bottom-verylarge {
    margin-bottom: 30px; } }

@media (min-width: 992px) {
  .cols {
    display: grid;
    grid-column-gap: 30px; }
  .cols--2 {
    grid-template-columns: repeat(2, 1fr); }
  .cols--3 {
    grid-template-columns: repeat(3, 1fr); }
  .cols--4 {
    grid-template-columns: repeat(4, 1fr); } }

@media (min-width: 1385px) {
  .cols {
    grid-column-gap: 60px; } }

/* Contentblocks */
.contentblock {
  margin-bottom: 8vw; }
  @media (min-width: 768px) {
    .contentblock {
      margin-bottom: 100px; } }
  .contentblock.margin-bottom-none {
    margin-bottom: 0vw; }
    @media (min-width: 768px) {
      .contentblock.margin-bottom-none {
        margin-bottom: 0; } }
  .contentblock.margin-bottom-tiny {
    margin-bottom: 4vw; }
    @media (min-width: 768px) {
      .contentblock.margin-bottom-tiny {
        margin-bottom: 15px; } }
  .contentblock.margin-bottom-small {
    margin-bottom: 8vw; }
    @media (min-width: 768px) {
      .contentblock.margin-bottom-small {
        margin-bottom: 30px; } }
  .contentblock.margin-bottom-medium {
    margin-bottom: 8vw; }
    @media (min-width: 768px) {
      .contentblock.margin-bottom-medium {
        margin-bottom: 60px; } }
  .contentblock.margin-bottom-large {
    margin-bottom: 8vw; }
    @media (min-width: 768px) {
      .contentblock.margin-bottom-large {
        margin-bottom: 90px; } }
  .contentblock.margin-bottom-verylarge {
    margin-bottom: 8vw; }
    @media (min-width: 768px) {
      .contentblock.margin-bottom-verylarge {
        margin-bottom: 120px; } }

/* Columns */
@media (min-width: 992px) {
  .columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px; }
  .columns--3 {
    grid-template-columns: repeat(3, 1fr); }
  .columns--4 {
    grid-template-columns: repeat(4, 1fr); }
  .columns--5 {
    grid-template-columns: repeat(5, 1fr); } }

/* The project may be customized here. */
.page-header--widgets {
  position: relative !important; }

/* Contains Customizations for the project */
/* elements */
.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
  background: #6E110C;
  border-color: #6E110C; }

.flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #6E110C; }

input[type=email],
input[type=text],
input[type=date],
input[type=password],
select,
textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 10.66667vw;
  line-height: 10.66667vw;
  border-radius: 1.33333vw;
  font-size: 3.73333vw;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 0;
  padding-bottom: 0; }
  @media (min-width: 768px) {
    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select,
    textarea {
      height: 40px; } }
  @media (min-width: 768px) {
    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select,
    textarea {
      line-height: 40px; } }
  @media (min-width: 768px) {
    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select,
    textarea {
      border-radius: 5px; } }
  @media (min-width: 768px) {
    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select,
    textarea {
      font-size: 14px; } }
  @media (min-width: 768px) {
    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select,
    textarea {
      padding-left: 15px; } }
  @media (min-width: 768px) {
    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select,
    textarea {
      padding-right: 15px; } }
  input[type=email]:focus,
  input[type=text]:focus,
  input[type=date]:focus,
  input[type=password]:focus,
  select:focus,
  textarea:focus {
    outline: none;
    border-color: #6E110C; }

@media (min-width: 768px) {
  .form-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px; } }

.form-element {
  margin-bottom: 5.33333vw; }
  @media (min-width: 768px) {
    .form-element {
      margin-bottom: 20px; } }
  .form-element label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3.2vw;
    line-height: 3.73333vw;
    margin-bottom: 1.86667vw;
    display: block; }
    @media (min-width: 768px) {
      .form-element label {
        font-size: 12px; } }
    @media (min-width: 768px) {
      .form-element label {
        line-height: 14px; } }
    @media (min-width: 768px) {
      .form-element label {
        margin-bottom: 7px; } }
  .form-element textarea {
    padding: 4vw;
    min-height: 53.33333vw; }
    @media (min-width: 768px) {
      .form-element textarea {
        padding: 15px; } }
    @media (min-width: 768px) {
      .form-element textarea {
        min-height: 200px; } }
  .form-element.checkbox label {
    font-size: 16px;
    line-height: 19px;
    text-transform: none;
    font-weight: 400;
    padding-top: 3px;
    padding-left: 38px;
    position: relative;
    margin-bottom: 40px; }
    .form-element.checkbox label a {
      color: black;
      font-weight: 500;
      text-decoration: underline; }
  .form-element.checkbox input[type=checkbox] {
    opacity: 0;
    height: 1px;
    width: 1px;
    pointer-events: none;
    position: absolute;
    top: 2.93333vw;
    left: 2.93333vw; }
    @media (min-width: 768px) {
      .form-element.checkbox input[type=checkbox] {
        top: 11px; } }
    @media (min-width: 768px) {
      .form-element.checkbox input[type=checkbox] {
        left: 11px; } }

.custom-checkbox {
  width: 22px;
  height: 22px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  margin-right: 15px;
  position: absolute;
  top: 0;
  left: 0; }

input[type=checkbox]:checked + .custom-checkbox:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #6E110C; }

.form-columns--twocols {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 8vw; }
  @media (min-width: 768px) {
    .form-columns--twocols {
      column-gap: 30px; } }

.form-columns--twocols-address {
  grid-template-columns: 33fr 66fr; }

.form-column .booking-numbers {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px; }

.form-column .form-element.booking-element--numbers {
  display: block;
  /*@include fluid(padding-right, 30px);
    @include fluid(padding-left, 30px);*/
  margin: 0;
  padding-bottom: 0; }
  .form-column .form-element.booking-element--numbers label {
    line-height: 3.73333vw;
    margin-bottom: 1.86667vw; }
    @media (min-width: 768px) {
      .form-column .form-element.booking-element--numbers label {
        line-height: 14px; } }
    @media (min-width: 768px) {
      .form-column .form-element.booking-element--numbers label {
        margin-bottom: 7px; } }

@media (min-width: 768px) {
  .inquiry-container {
    margin-bottom: 100px; }
  .inquiry-columns {
    column-count: 2;
    column-gap: 100px;
    break-inside: avoid; }
  .form--button-container {
    text-align: center;
    margin-bottom: 100px; }
    .form--button-container .btn {
      cursor: pointer; }
  .contentblock--form .container--narrow {
    max-width: 1024px; } }

/* Error Message */
.error-message {
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.2);
  padding: 5.33333vw;
  text-align: center;
  margin-bottom: 10.66667vw; }
  @media (min-width: 768px) {
    .error-message {
      padding: 20px; } }
  @media (min-width: 768px) {
    .error-message {
      margin-bottom: 40px; } }

/* Main Button */
.btn {
  display: inline-block;
  background: #6E110C;
  color: white;
  border-color: #6E110C;
  border-style: solid;
  border-width: 0.53333vw;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 9.6vw;
  font-size: 3.46667vw;
  border-radius: 8vw;
  padding-left: 6.66667vw;
  padding-right: 6.66667vw;
  transition: box-shadow ease-in-out .2s, background ease-in-out .2s, border-color ease-in-out .2s, color ease-in-out .2s; }
  @media (min-width: 768px) {
    .btn {
      border-width: 2px; } }
  @media (min-width: 768px) {
    .btn {
      line-height: 56px; } }
  @media (min-width: 768px) {
    .btn {
      font-size: 15px; } }
  @media (min-width: 768px) {
    .btn {
      border-radius: 30px; } }
  @media (min-width: 768px) {
    .btn {
      padding-left: 30px; } }
  @media (min-width: 768px) {
    .btn {
      padding-right: 30px; } }
  .btn:hover {
    background: black;
    color: white;
    border-color: black; }
  .btn.btn--flat {
    line-height: 9.6vw; }
    @media (min-width: 768px) {
      .btn.btn--flat {
        line-height: 36px; } }
  .btn.btn--outline {
    background: transparent;
    border-color: black;
    color: black;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0); }
    .btn.btn--outline:hover {
      background: white;
      color: #6E110C;
      border-color: white;
      box-shadow: 0 0 60px rgba(0, 0, 0, 0.1); }
  .btn.btn--outline-negative {
    background: transparent;
    color: white;
    border-color: white; }
    @media (min-width: 1385px) {
      .btn.btn--outline-negative:hover {
        background: transparent;
        color: #6E110C;
        border-color: #6E110C; } }
  .btn.btn--outline-negative--variant {
    background: white;
    color: #6E110C;
    border-color: white; }

a.arrow-link {
  color: #333333; }
  a.arrow-link i {
    font-size: 3.2vw;
    margin-right: 4vw;
    color: rgba(0, 0, 0, 0.1);
    transition: color ease-in-out .3s; }
    @media (min-width: 768px) {
      a.arrow-link i {
        font-size: 12px; } }
    @media (min-width: 768px) {
      a.arrow-link i {
        margin-right: 15px; } }
  a.arrow-link:hover i {
    color: #6E110C;
    opacity: 1; }

.prev, .next {
  display: inline-grid;
  width: 12.26667vw;
  height: 12.26667vw;
  border-radius: 12.26667vw;
  background: #6E110C;
  cursor: pointer;
  place-items: center; }
  @media (min-width: 768px) {
    .prev, .next {
      width: 46px; } }
  @media (min-width: 768px) {
    .prev, .next {
      height: 46px; } }
  @media (min-width: 768px) {
    .prev, .next {
      border-radius: 46px; } }

.prev--arrow {
  display: inline-block;
  width: 2.66667vw;
  height: 2.66667vw;
  border-width: 0.53333vw 0.53333vw 0 0;
  border-style: solid;
  border-color: white;
  transform: rotate(-135deg);
  margin-left: 0.80001vw; }
  @media (min-width: 768px) {
    .prev--arrow {
      margin-left: 3.00003px; } }
  @media (min-width: 768px) {
    .prev--arrow {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-color: white;
      transform: rotate(-135deg); } }

.next--arrow {
  display: inline-block;
  width: 2.66667vw;
  height: 2.66667vw;
  border-width: 0.53333vw 0.53333vw 0 0;
  border-style: solid;
  border-color: white;
  transform: rotate(45deg);
  margin-left: -0.80001vw; }
  @media (min-width: 768px) {
    .next--arrow {
      margin-left: -3.00003px; } }
  @media (min-width: 768px) {
    .next--arrow {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-color: white;
      transform: rotate(45deg); } }

.contentblock .teaser,
.teaser {
  display: block;
  border-radius: 5.33333vw;
  background: white;
  overflow: hidden;
  position: relative;
  background: white;
  transition: background ease-in-out 0.3s; }
  @media (min-width: 768px) {
    .contentblock .teaser,
    .teaser {
      border-radius: 20px; } }
  .contentblock .teaser h3,
  .teaser h3 {
    font-size: 6.66667vw;
    line-height: 7.73333vw;
    margin-bottom: 6.66667vw; }
    @media (min-width: 768px) {
      .contentblock .teaser h3,
      .teaser h3 {
        font-size: 25px; } }
    @media (min-width: 768px) {
      .contentblock .teaser h3,
      .teaser h3 {
        line-height: 29px; } }
    @media (min-width: 768px) {
      .contentblock .teaser h3,
      .teaser h3 {
        margin-bottom: 25px; } }
  .contentblock .teaser .arrow-right,
  .teaser .arrow-right {
    position: absolute;
    width: 4.26667vw;
    height: 4.26667vw;
    right: 8vw;
    bottom: 8vw;
    color: rgba(0, 0, 0, 0.1);
    transition: color ease-in-out 0.3s; }
    @media (min-width: 768px) {
      .contentblock .teaser .arrow-right,
      .teaser .arrow-right {
        width: 16px; } }
    @media (min-width: 768px) {
      .contentblock .teaser .arrow-right,
      .teaser .arrow-right {
        height: 16px; } }
    @media (min-width: 768px) {
      .contentblock .teaser .arrow-right,
      .teaser .arrow-right {
        right: 30px; } }
    @media (min-width: 768px) {
      .contentblock .teaser .arrow-right,
      .teaser .arrow-right {
        bottom: 30px; } }
    .contentblock .teaser .arrow-right i,
    .teaser .arrow-right i {
      font-size: 4.53333vw; }
      @media (min-width: 768px) {
        .contentblock .teaser .arrow-right i,
        .teaser .arrow-right i {
          font-size: 17px; } }
  .contentblock .teaser h3, .contentblock .teaser p,
  .teaser h3,
  .teaser p {
    color: black;
    transition: color ease-in-out 0.3s; }
  .contentblock .teaser:hover,
  .teaser:hover {
    background: #6E110C; }
    .contentblock .teaser:hover .arrow-right,
    .teaser:hover .arrow-right {
      color: white; }
    .contentblock .teaser:hover h3, .contentblock .teaser:hover p,
    .teaser:hover h3,
    .teaser:hover p {
      color: white; }

.teaser--image {
  width: 100%;
  height: 0;
  padding-bottom: 56.21622%;
  position: relative; }
  .teaser--image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.teaser--content-container {
  color: black;
  padding-top: 10.66667vw;
  padding-right: 10.66667vw;
  padding-bottom: 16vw;
  padding-left: 10.66667vw; }
  @media (min-width: 768px) {
    .teaser--content-container {
      padding-top: 40px; } }
  @media (min-width: 768px) {
    .teaser--content-container {
      padding-right: 40px; } }
  @media (min-width: 768px) {
    .teaser--content-container {
      padding-bottom: 60px; } }
  @media (min-width: 768px) {
    .teaser--content-container {
      padding-left: 40px; } }

.teaser--small {
  display: grid;
  grid-template-columns: 28.53333vw auto; }
  @media (min-width: 768px) {
    .teaser--small {
      grid-template-columns: 107px auto; } }
  .teaser--small .teaser--image {
    padding-bottom: 0;
    height: 100%; }
    .teaser--small .teaser--image img {
      height: 100%; }

/* Teaserslider Controls */
.teaserslider {
  position: relative; }

@media (max-width: 879.98px) {
  .teaserslider--controls .prev,
  .teaserslider--controls .next {
    position: absolute;
    top: 24.53333vw; }
  .teaserslider--controls .prev {
    left: 3.2vw; }
  .teaserslider--controls .next {
    right: 3.2vw; } }

@media (min-width: 768px) {
  .teaserslider--controls .prev,
  .teaserslider--controls .next {
    position: absolute;
    top: 70px; }
  .teaserslider--controls .prev {
    left: -20px; }
  .teaserslider--controls .next {
    right: -20px; } }

@media (min-width: 1024px) {
  .teaserslider--controls .prev,
  .teaserslider--controls .next {
    position: absolute;
    top: 90px; }
  .teaserslider--controls .prev {
    left: -20px; }
  .teaserslider--controls .next {
    right: -20px; } }

.logo {
  background-image: svg-load("../_images/svg/Logo_Altenberger_2021.svg");
  width: 100%;
  height: 0;
  padding-bottom: 40.60914%;
  display: block;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999em; }

.logo--black {
  background-image: svg-load("../_images/svg/Logo_Altenberger_2021.svg");
  background-size: contain; }

.logo--flat {
  background-image: svg-load("../_images/svg/logo-conecto.svg");
  width: 37.33333vw;
  height: 2.13333vw;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  color: rgba(0, 0, 0, 0.4);
  transition: opacity ease-in-out .3s;
  opacity: .4; }
  @media (min-width: 768px) {
    .logo--flat {
      width: 140px; } }
  @media (min-width: 768px) {
    .logo--flat {
      height: 8px; } }
  .logo--flat:hover {
    opacity: 1; }

/* page-header */
.page-header {
  position: relative; }

@media (min-width: 768px) {
  .subpage .page-header {
    height: 750px; } }

/* Grid area definitions */
.page-header--logo {
  grid-area: logo; }

.page-header--nav {
  grid-area: nav; }

.page-header--widgets {
  grid-area: widgets; }

.page-header--meta {
  grid-area: meta; }

@media (min-width: 768px) {
  .page-header {
    overflow: hidden; }
  /* 1st Level */
  .page-header--image {
    position: relative;
    z-index: 50; }
  .page-header--boxed {
    position: relative;
    z-index: 100; }
  .page-header--meta {
    position: relative;
    z-index: 100; }
  /* Main Level */
  .page-header {
    height: 100vh;
    display: grid;
    grid-template-areas: 'meta meta meta' '. logo-nav-widgets .';
    grid-template-columns: 1fr minmax(0, 135.83333vw) 1fr;
    grid-template-rows: 49px auto; } }
  @media (min-width: 768px) and (min-width: 1630px) {
    .page-header {
      grid-template-columns: 1fr minmax(0, 1630px) 1fr; } }

.page-header--meta {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 4vw;
  padding-bottom: 4vw;
  padding-left: 5.33333vw;
  font-size: 3.73333vw;
  line-height: 5.33333vw;
  opacity: 1;
  transition: opacity .5s ease-in-out;
  transition-delay: .25s; }
  @media (min-width: 768px) {
    .page-header--meta {
      padding-top: 0; } }
  @media (min-width: 768px) {
    .page-header--meta {
      padding-bottom: 0; } }
  @media (min-width: 768px) {
    .page-header--meta {
      padding-left: 20px; } }
  @media (min-width: 768px) {
    .page-header--meta {
      font-size: 14px; } }
  @media (min-width: 768px) {
    .page-header--meta {
      line-height: 49px; } }

/*.preload {
  .page-header--meta {
    opacity: 0;
  }
}*/
.main-nav--fixed .page-header--meta {
  opacity: 0;
  transition: opacity 0s ease-in-out;
  transition-delay: 0s; }

@media (max-width: 767.98px) {
  .page-header--meta {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999; } }

.page-header--contact,
.page-header--social,
.page-header--language {
  height: 5.33333vw;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block; }
  @media (min-width: 768px) {
    .page-header--contact,
    .page-header--social,
    .page-header--language {
      height: 49px; } }
  .page-header--contact li,
  .page-header--social li,
  .page-header--language li {
    display: inline-block; }
  .page-header--contact a,
  .page-header--social a,
  .page-header--language a {
    color: white;
    line-height: 5.33333vw;
    display: inline-block;
    float: left; }
    @media (min-width: 768px) {
      .page-header--contact a,
      .page-header--social a,
      .page-header--language a {
        line-height: 49px; } }
    .page-header--contact a i,
    .page-header--social a i,
    .page-header--language a i {
      line-height: 5.33333vw;
      display: inline-block;
      float: left; }
      @media (min-width: 768px) {
        .page-header--contact a i,
        .page-header--social a i,
        .page-header--language a i {
          line-height: 49px; } }
      .page-header--contact a i:before,
      .page-header--social a i:before,
      .page-header--language a i:before {
        font-size: 5.33333vw;
        display: inline-block;
        float: left; }
        @media (min-width: 768px) {
          .page-header--contact a i:before,
          .page-header--social a i:before,
          .page-header--language a i:before {
            font-size: 20px; } }

.page-header--meta-left i {
  margin-right: 1.33333vw;
  margin-right: 1.33333vw; }
  @media (min-width: 768px) {
    .page-header--meta-left i {
      margin-right: 10px; } }

@media (min-width: 768px) {
  .page-header--meta {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
  .page-header--meta-left,
  .page-header--meta-right {
    height: 49px; }
  .page-header--meta-left li {
    margin-right: 20px; }
  .page-header--meta-right {
    text-align: right; }
  .page-header--social li {
    float: left; }
    .page-header--social li a {
      text-align: center; }
      .page-header--social li a i:before {
        width: 46px; }
  .page-header--language {
    float: right; }
    .page-header--language li a {
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      width: 49px;
      text-align: center; } }

@media (max-width: 767.98px) {
  .page-header--logo {
    position: absolute;
    top: 13.33333vw;
    padding-top: 4vw;
    z-index: 5;
    width: 66.66667vw;
    left: 50%;
    transform: translateX(-50%); }
    .page-header--logo .logo {
      width: 100%; }
  .page-header--image {
    position: relative; }
  .page-header--widgets {
    margin: -8vw auto 0; }
  .page-header--cta {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    top: calc(120vw - 15.73333vw);
    transform: translateY(-100%); }
  .page-header--boxed {
    background-color: #f2f2f2; } }

@media (min-width: 768px) {
  .page-header--boxed {
    width: 100%;
    grid-area: logo-nav-widgets;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 7.2vw;
    padding-bottom: 98px;
    display: grid;
    grid-template-areas: 'logo nav' '. widgets';
    grid-template-columns: 300px auto;
    grid-template-rows: 80px auto; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .page-header--boxed {
      padding-left: 30px; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .page-header--boxed {
      padding-right: 30px; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .page-header--boxed {
      padding-top: 27px; } }

@media (min-width: 768px) {
  .page-header--logo .logo {
    width: 100%; }
  .page-header--widgets {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
  .page-header--nav {
    text-align: right; } }

.page-header--cta {
  width: 100%;
  align-self: flex-end;
  z-index: 1;
  text-align: center;
  padding-left: 8vw;
  padding-right: 8vw;
  white-space: nowrap; }
  .page-header--cta h1 {
    font-size: 12vw;
    line-height: 10.66667vw;
    margin-bottom: 4vw;
    font-weight: 900;
    color: white;
    white-space: normal; }
    .page-header--cta h1 p {
      margin-bottom: 0; }

@media (min-width: 768px) {
  .page-header--cta {
    padding: 0; }
    .page-header--cta h1 {
      font-size: 4.90798vw;
      line-height: 5.52147vw;
      margin-bottom: 1.22699vw;
      width: 46.01227vw;
      white-space: normal;
      text-align: left; } }
    @media (min-width: 768px) and (min-width: 1630px) {
      .page-header--cta h1 {
        font-size: 80px; } }
    @media (min-width: 768px) and (min-width: 1630px) {
      .page-header--cta h1 {
        line-height: 90px; } }
    @media (min-width: 768px) and (min-width: 1630px) {
      .page-header--cta h1 {
        margin-bottom: 20px; } }
    @media (min-width: 768px) and (min-width: 1630px) {
      .page-header--cta h1 {
        width: 750px; } }

@media (min-width: 768px) {
    .page-header--cta > :last-child {
      margin-bottom: 0; }
  /* Animations */
  .page-header--cta > *:nth-child(1) {
    animation: 1s ease-out 0s 1 animationOne; }
  .page-header--cta > *:nth-child(2) {
    animation: 1s ease-out 0s 1 animationTwo; }
  .page-header--cta > *:nth-child(3) {
    transition: transform ease-out 1s, background ease-in-out .2s, border-color ease-in-out .2s, color ease-in-out .2s;
    animation: 1s ease-out 0s 1 animationThree; }
  @keyframes animationOne {
    0% {
      opacity: 0;
      transform: translateX(100px); }
    100% {
      opacity: 1;
      transform: translateX(0); } }
  @keyframes animationTwo {
    0% {
      opacity: 0;
      transform: translateX(75px); }
    100% {
      opacity: 1;
      transform: translateX(0); } }
  @keyframes animationThree {
    0% {
      opacity: 0;
      transform: translateX(125px); }
    100% {
      opacity: 1;
      transform: translateX(0); } } }

.page-header--image {
  width: 100vw;
  height: 120vw; }
  @media (min-width: 768px) {
    .page-header--image {
      height: 50vh; } }
  .page-header--image:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 33.33333vw;
    background: linear-gradient(180deg, rgba(24, 52, 89, 0) 0%, rgba(24, 52, 89, 0.4) 100%);
    bottom: 0; }
    @media (min-width: 768px) {
      .page-header--image:after {
        height: 125px; } }
  .page-header--image:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 6.66667vw;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    z-index: 101; }
    @media (min-width: 768px) {
      .page-header--image:before {
        height: 25vh; } }
  .page-header--image img, .page-header--image video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

@media (min-width: 768px) {
  .page-header--image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; }
    .page-header--image:after {
      height: 80vw; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .page-header--image:after {
        height: 300px; } }

/* page-header widgets */
.page-header--widgets {
  /*.teaser:not(.teaser--small) {
    box-shadow: none;
  }*/ }
  .page-header--widgets .teaserslider {
    margin-top: -16vw; }
    .page-header--widgets .teaserslider .splide__track {
      /*padding-top: pxToVwMobile(30px);
      padding-bottom: pxToVwMobile(30px);*/
      padding-top: 8vw;
      padding-bottom: 8vw;
      border-radius: 5.33333vw; }
      @media (min-width: 768px) {
        .page-header--widgets .teaserslider .splide__track {
          padding-top: 0; } }
      @media (min-width: 768px) {
        .page-header--widgets .teaserslider .splide__track {
          padding-bottom: 0; } }
      @media (min-width: 768px) {
        .page-header--widgets .teaserslider .splide__track {
          border-radius: 20px; } }
  .page-header--widgets .teaser--content-container {
    padding-top: 4.848vw;
    padding-left: 5.33333vw;
    padding-right: 13.33333vw;
    padding-bottom: 4.49067vw; }
    @media (min-width: 768px) {
      .page-header--widgets .teaser--content-container {
        padding-top: 20px; } }
    @media (min-width: 768px) {
      .page-header--widgets .teaser--content-container {
        padding-left: 20px; } }
    @media (min-width: 768px) {
      .page-header--widgets .teaser--content-container {
        padding-right: 60px; } }
    @media (min-width: 768px) {
      .page-header--widgets .teaser--content-container {
        padding-bottom: 19px; } }
  .page-header--widgets .teaser h3 {
    min-height: 8.53333vw;
    font-size: 4.26667vw;
    line-height: 5.06667vw;
    margin: 0;
    font-weight: 500; }
    @media (min-width: 768px) {
      .page-header--widgets .teaser h3 {
        min-height: 46px; } }
    @media (min-width: 768px) {
      .page-header--widgets .teaser h3 {
        font-size: 20px; } }
    @media (min-width: 768px) {
      .page-header--widgets .teaser h3 {
        line-height: 23px; } }
  .page-header--widgets .teaser .arrow-right {
    right: 5.33333vw;
    bottom: 5.33333vw; }
    @media (min-width: 768px) {
      .page-header--widgets .teaser .arrow-right {
        right: 20px; } }
    @media (min-width: 768px) {
      .page-header--widgets .teaser .arrow-right {
        bottom: 20px; } }
  .page-header--widgets .tns-outer {
    box-shadow: 0 0 16vw rgba(0, 0, 0, 0.1);
    border-radius: 5.33333vw;
    overflow: hidden; }
    @media (min-width: 768px) {
      .page-header--widgets .tns-outer {
        box-shadow: 0 0 60px rgba(0, 0, 0, 0.1); } }
    @media (min-width: 768px) {
      .page-header--widgets .tns-outer {
        border-radius: 20px; } }
  @media (min-width: 768px) {
    .page-header--widgets {
      /*.teaserslider--controls {
        .prev,
        .next {
          top: 70px;
        }
      }*/ }
      .page-header--widgets .teaserslider {
        max-width: 320px;
        align-self: flex-end; } }
  @media (min-width: 1024px) {
    .page-header--widgets {
      /*.teaserslider--controls {
        .prev,
        .next {
          top: 90px;
        }
      }*/ }
      .page-header--widgets .teaserslider {
        max-width: 370px; } }

.page-header--smallteasers {
  display: grid;
  grid-row-gap: 2.66667vw; }
  @media (min-width: 768px) {
    .page-header--smallteasers {
      grid-row-gap: 10px; } }
  .page-header--smallteasers .teaser--small h3 {
    font-weight: 900; }
  @media (max-width: 767.98px) {
    .page-header--smallteasers {
      margin-left: 8vw;
      margin-right: 8vw; } }
  @media (min-width: 768px) {
    .page-header--smallteasers {
      align-self: flex-end;
      max-width: 360px; } }

.page-header--booking {
  background: white;
  border-radius: 5.33333vw;
  padding-top: 5.33333vw;
  padding-bottom: 5.33333vw; }
  @media (min-width: 768px) {
    .page-header--booking {
      border-radius: 20px; } }
  @media (min-width: 768px) {
    .page-header--booking {
      padding-top: 30px; } }
  @media (min-width: 768px) {
    .page-header--booking {
      padding-bottom: 30px; } }
  @media (min-width: 768px) {
    .page-header--booking {
      max-width: 370px; } }
  .page-header--booking .form-element {
    margin-bottom: 0; }

.booking-dates {
  padding-right: 5.33333vw;
  padding-bottom: 5.33333vw;
  padding-left: 5.33333vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3.73333vw; }
  @media (min-width: 768px) {
    .booking-dates {
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .booking-dates {
      padding-bottom: 30px; } }
  @media (min-width: 768px) {
    .booking-dates {
      padding-left: 30px; } }
  @media (min-width: 768px) {
    .booking-dates {
      grid-column-gap: 14px; } }
  .booking-dates input {
    padding-left: 2.13333vw; }
    @media (min-width: 768px) {
      .booking-dates input {
        padding-left: 15px; } }

input.datepicker {
  background-image: svg-load("../_images/svg/calendar.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 5.86667vw 5.06667vw;
  cursor: default; }
  @media (min-width: 768px) {
    input.datepicker {
      background-size: 22px 19px; } }

/*.booking-dates,
.form-columns--twocols-dates {
  input {

  }
}*/
.booking-numbers {
  padding: 5.33333vw; }
  @media (min-width: 768px) {
    .booking-numbers {
      padding: 30px; } }

.booking-button {
  text-align: right;
  padding-left: 5.33333vw;
  padding-right: 5.33333vw; }
  @media (min-width: 768px) {
    .booking-button {
      padding-left: 30px; } }
  @media (min-width: 768px) {
    .booking-button {
      padding-right: 30px; } }
  .booking-button .btn {
    cursor: pointer; }

/* Numbers */
.form-element.booking-element--numbers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3.73333vw;
  padding-bottom: 4vw; }
  @media (min-width: 768px) {
    .form-element.booking-element--numbers {
      grid-column-gap: 14px; } }
  @media (min-width: 768px) {
    .form-element.booking-element--numbers {
      padding-bottom: 15px; } }
  .form-element.booking-element--numbers label {
    margin-bottom: 0;
    line-height: 10.66667vw; }
    @media (min-width: 768px) {
      .form-element.booking-element--numbers label {
        line-height: 40px; } }
  .form-element.booking-element--numbers input[type=text] {
    text-align: center;
    padding: 0;
    border: none;
    font-size: 4.26667vw;
    font-weight: 500; }
    @media (min-width: 768px) {
      .form-element.booking-element--numbers input[type=text] {
        font-size: 16px; } }

.booking-numbers .booking-element--numbers:last-child {
  padding-bottom: 0; }

button.minus, button.plus {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  width: 10.66667vw;
  height: 10.66667vw;
  line-height: 10.66667vw;
  border-radius: 10.66667vw;
  text-indent: -9999em;
  position: relative; }
  @media (min-width: 768px) {
    button.minus, button.plus {
      width: 40px; } }
  @media (min-width: 768px) {
    button.minus, button.plus {
      height: 40px; } }
  @media (min-width: 768px) {
    button.minus, button.plus {
      line-height: 40px; } }
  @media (min-width: 768px) {
    button.minus, button.plus {
      border-radius: 40px; } }
  button.minus:before, button.minus:after, button.plus:before, button.plus:after {
    content: '';
    width: 3.73333vw;
    height: 0.53333vw;
    background: black;
    position: absolute;
    top: 50%;
    left: 50%; }
    @media (min-width: 768px) {
      button.minus:before, button.minus:after, button.plus:before, button.plus:after {
        width: 14px; } }
    @media (min-width: 768px) {
      button.minus:before, button.minus:after, button.plus:before, button.plus:after {
        height: 2px; } }
  button.minus:before, button.plus:before {
    transform: translateX(-50%) translateY(-50%); }
  button.minus:after, button.plus:after {
    transform: translateX(-50%) translateY(-50%) rotate(90deg); }

button.minus:after {
  display: none; }

/* Numberpicker */
.numberpicker {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 2.93333vw; }
  @media (min-width: 768px) {
    .numberpicker {
      grid-column-gap: 11px; } }

.main-nav--toggle {
  display: none; }

@media (max-width: 1384.98px) {
  /* Main Nav Top */
  .main-nav--top {
    height: 50px;
    width: 60px;
    overflow: hidden;
    z-index: 999999;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    backface-visibility: hidden;
    perspective: 1000; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav--top {
      height: 13.33333vw; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav--top {
      width: 16vw; } }

@media (max-width: 1384.98px) {
  /* Mobile Menu Special */
  .mobile-menu-top {
    height: 50px;
    position: absolute;
    top: 0;
    right: 60px;
    width: 0;
    opacity: 0;
    white-space: nowrap; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .mobile-menu-top {
      height: 13.33333vw; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .mobile-menu-top {
      right: 16vw; } }

@media (max-width: 1384.98px) {
    .mobile-menu-top a {
      color: white;
      line-height: 49px;
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      display: inline-block;
      transition: opacity .15s ease-in-out; } }
    @media (max-width: 1384.98px) and (max-width: 767.98px) {
      .mobile-menu-top a {
        line-height: 13.06667vw; } }
    @media (max-width: 1384.98px) and (max-width: 767.98px) {
      .mobile-menu-top a {
        font-size: 3.73333vw; } }

@media (max-width: 1384.98px) {
      .mobile-menu-top a i {
        line-height: 49px;
        margin-right: 5px;
        display: inline-block;
        float: left; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .mobile-menu-top a i {
          line-height: 13.06667vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .mobile-menu-top a i {
          margin-right: 1.33333vw; } }

@media (max-width: 1384.98px) {
        .mobile-menu-top a i:before {
          font-size: 5.33333vw;
          display: inline-block;
          float: left; } }
        @media (max-width: 1384.98px) and (min-width: 768px) {
          .mobile-menu-top a i:before {
            font-size: 20px; } }

@media (max-width: 1384.98px) {
  .mobile-menu--contact {
    display: inline-block;
    padding-left: 5.33333vw;
    position: absolute;
    left: 0;
    top: 0; }
  .mobile-menu--language {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0; }
    .mobile-menu--language a {
      padding-left: 4vw;
      padding-right: 4vw;
      border-left: 1px solid rgba(255, 255, 255, 0.2); } }
    @media (max-width: 1384.98px) and (min-width: 768px) {
      .mobile-menu--language a {
        padding-left: 15px; } }
    @media (max-width: 1384.98px) and (min-width: 768px) {
      .mobile-menu--language a {
        padding-right: 15px; } }

@media (max-width: 1384.98px) {
  /* Main Nav Bottom */
  .mobile-menu-bottom {
    text-align: center;
    white-space: nowrap; }
  .mobile-menu-bottom--buttons {
    margin: 0;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    list-style-type: none; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .mobile-menu-bottom--buttons {
      margin-top: 8vw; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .mobile-menu-bottom--buttons {
      margin-bottom: 8vw; } }

@media (max-width: 1384.98px) {
    .mobile-menu-bottom--buttons li {
      display: inline-block;
      padding-right: 7px;
      padding-left: 7px; } }
    @media (max-width: 1384.98px) and (max-width: 767.98px) {
      .mobile-menu-bottom--buttons li {
        padding-right: 1.86667vw; } }
    @media (max-width: 1384.98px) and (max-width: 767.98px) {
      .mobile-menu-bottom--buttons li {
        padding-left: 1.86667vw; } }

@media (max-width: 1384.98px) {
  .page-header--social li {
    float: left; }
    .page-header--social li a {
      text-align: center; }
      .page-header--social li a i:before {
        width: 46px; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .page-header--social li a i:before {
          width: 12.26667vw; } }

@media (max-width: 1384.98px) {
  .main-nav--burger {
    backface-visibility: hidden;
    perspective: 1000;
    width: 60px;
    height: 50px;
    align-self: center;
    cursor: pointer;
    transition: all ease-in-out .15s;
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    place-items: center;
    border-left: 1px solid rgba(255, 255, 255, 0);
    z-index: 20001; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav--burger {
      width: 16vw; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav--burger {
      height: 13.33333vw; } }

@media (max-width: 1384.98px) {
    .main-nav--burger span {
      display: block;
      width: 30px;
      height: 2px;
      background: white;
      position: relative;
      transition: all ease-in-out .3s; } }
    @media (max-width: 1384.98px) and (max-width: 767.98px) {
      .main-nav--burger span {
        width: 8vw; } }
    @media (max-width: 1384.98px) and (max-width: 767.98px) {
      .main-nav--burger span {
        height: 0.53333vw; } }

@media (max-width: 1384.98px) {
      .main-nav--burger span:before, .main-nav--burger span:after {
        content: '';
        display: block;
        width: 30px;
        height: 2px;
        min-height: 2px;
        background: white;
        position: absolute;
        left: 0;
        transition: all ease-in-out .3s;
        opacity: 1; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav--burger span:before, .main-nav--burger span:after {
          width: 8vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav--burger span:before, .main-nav--burger span:after {
          height: 0.53333vw; } }

@media (max-width: 1384.98px) {
      .main-nav--burger span:before {
        top: -7px;
        transform-origin: center top; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav--burger span:before {
          top: -1.86667vw; } }

@media (max-width: 1384.98px) {
      .main-nav--burger span:after {
        bottom: -7px;
        transform-origin: center bottom; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav--burger span:after {
          bottom: -1.86667vw; } }

@media (max-width: 1384.98px) {
  .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger {
    transition: all ease-in-out .3s;
    border-left: 1px solid rgba(255, 255, 255, 0.2); }
    .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span {
      background-color: transparent; }
      .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span:before, .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span:after {
        background: #FFFFFF; }
      .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span:before {
        transform: rotate(45deg) translateX(2.66667vw);
        margin-left: -8px; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span:before {
          margin-left: -2.13333vw; } }
      @media (max-width: 1384.98px) and (min-width: 768px) {
        .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span:before {
          transform: rotate(45deg) translateX(10px); } }

@media (max-width: 1384.98px) {
      .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span:after {
        transform: rotate(-45deg) translateX(2.66667vw);
        margin-left: -8px; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span:after {
          margin-left: -2.13333vw; } }
      @media (max-width: 1384.98px) and (min-width: 768px) {
        .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger span:after {
          transform: rotate(-45deg) translateX(10px); } }

@media (max-width: 1384.98px) {
  .main-nav--toggle:checked ~ .main-nav--top {
    background-color: #6E110C;
    position: fixed;
    width: 375px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    transition: all .15s ease-in-out; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav--toggle:checked ~ .main-nav--top {
      width: 100vw; } }

@media (max-width: 1384.98px) {
    .main-nav--toggle:checked ~ .main-nav--top .mobile-menu-top {
      transition: width ease-in-out .33s, opacity ease-in-out .5s;
      transition-delay: .15s;
      opacity: 1;
      width: calc(100vw - 16vw); } }
    @media (max-width: 1384.98px) and (min-width: 768px) {
      .main-nav--toggle:checked ~ .main-nav--top .mobile-menu-top {
        width: calc(375px - 60px); } }

@media (max-width: 1384.98px) {
  .main-nav--toggle:checked ~ .main-nav {
    width: 100vw;
    opacity: 1;
    position: fixed;
    backface-visibility: hidden;
    perspective: 1000;
    transition: all ease-in-out .15s; } }
  @media (max-width: 1384.98px) and (min-width: 768px) {
    .main-nav--toggle:checked ~ .main-nav {
      width: 375px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); } }

@media (max-width: 1384.98px) {
  .main-nav {
    z-index: 20000;
    width: 0;
    height: 100vh;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    overflow-y: auto;
    background: #6E110C;
    top: 50px;
    text-align: left; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav {
      top: 13.33333vw; } }

@media (max-width: 1384.98px) {
    .main-nav > ul {
      margin: 0;
      padding: 0;
      list-style-type: none; }
      .main-nav > ul li {
        display: block;
        position: relative;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
      .main-nav > ul a,
      .main-nav > ul a.btn {
        display: block;
        color: white;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        border: none;
        border-radius: 0;
        font-weight: 500;
        padding-top: 24px;
        padding-right: 105px;
        padding-bottom: 24px;
        padding-left: 30px;
        transition: background-color .3s ease-in-out; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav > ul a,
        .main-nav > ul a.btn {
          font-size: 4.8vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav > ul a,
        .main-nav > ul a.btn {
          line-height: 5.6vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav > ul a,
        .main-nav > ul a.btn {
          padding-top: 6.4vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav > ul a,
        .main-nav > ul a.btn {
          padding-right: 28vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav > ul a,
        .main-nav > ul a.btn {
          padding-bottom: 6.4vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav > ul a,
        .main-nav > ul a.btn {
          padding-left: 8vw; } }

@media (max-width: 1384.98px) {
        .main-nav > ul a:hover,
        .main-nav > ul a.btn:hover {
          color: white; }
    .main-nav .main-nav--first-level {
      display: block; }
    .main-nav .main-nav--sub {
      max-height: 0;
      overflow: hidden;
      transition: all ease-in-out .3s;
      background-color: transparent;
      border-top: none;
      padding-left: 0; }
      .main-nav .main-nav--sub li {
        border-bottom: none; }
      .main-nav .main-nav--sub a {
        padding-top: 20px;
        padding-right: 30px;
        padding-bottom: 20px;
        padding-left: 80px; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav .main-nav--sub a {
          padding-top: 5.33333vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav .main-nav--sub a {
          padding-right: 8vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav .main-nav--sub a {
          padding-bottom: 5.33333vw; } }
      @media (max-width: 1384.98px) and (max-width: 767.98px) {
        .main-nav .main-nav--sub a {
          padding-left: 21.33333vw; } }

@media (max-width: 1384.98px) {
        .main-nav .main-nav--sub a:before {
          content: "";
          display: block;
          position: absolute;
          display: inline-block;
          width: 2.66667vw;
          height: 2.66667vw;
          border-width: 0.53333vw 0.53333vw 0 0;
          border-style: solid;
          border-color: white;
          transform: rotate(45deg);
          position: absolute;
          left: 49px;
          top: 24px; } }
        @media (max-width: 1384.98px) and (min-width: 768px) {
          .main-nav .main-nav--sub a:before {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-width: 2px 2px 0 0;
            border-style: solid;
            border-color: white;
            transform: rotate(45deg); } }
        @media (max-width: 1384.98px) and (max-width: 767.98px) {
          .main-nav .main-nav--sub a:before {
            left: 13.06667vw; } }
        @media (max-width: 1384.98px) and (max-width: 767.98px) {
          .main-nav .main-nav--sub a:before {
            top: 6.4vw; } }

@media (max-width: 1384.98px) {
  .main-nav .next {
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: #6E110C;
    position: absolute;
    right: 30px;
    top: 12px;
    transition: border-color .15s ease-in-out; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav .next {
      right: 8vw; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav .next {
      top: 3.2vw; } }

@media (max-width: 1384.98px) {
  .main-nav .next--arrow {
    transition: transform .15s ease-in-out; }
  .main-nav .dropdown {
    display: none; }
  .main-nav .dropdown:checked ~ .next {
    border-color: #6E110C; }
    .main-nav .dropdown:checked ~ .next .next--arrow {
      transform: rotate(135deg) translateX(-3px); }
  .main-nav .dropdown:checked ~ a {
    background-color: #400a07; }
  .main-nav .dropdown:checked ~ .main-nav--sub {
    max-height: 1000px;
    padding-bottom: 20px;
    background-color: #400a07; } }
  @media (max-width: 1384.98px) and (max-width: 767.98px) {
    .main-nav .dropdown:checked ~ .main-nav--sub {
      padding-bottom: 5.33333vw; } }

@media (min-width: 768px) and (max-width: 1384.98px) {
  .page-header--boxed {
    position: relative;
    z-index: 101; }
  .main-nav--top {
    height: 100px; }
  .main-nav--toggle:checked ~ .main-nav--top {
    height: 49px; }
    .main-nav--toggle:checked ~ .main-nav--top .main-nav--burger {
      top: 0;
      right: 0; }
  .main-nav--burger {
    top: 30px;
    right: 16px; }
  .main-nav {
    top: 49px;
    right: -375px; }
  .main-nav--overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    background: black;
    top: 0;
    right: -100vw;
    opacity: 0;
    transition: opacity ease-in-out .5s; }
  .main-nav--toggle:checked ~ .main-nav {
    right: 0; }
  .main-nav--toggle:checked ~ .main-nav--overlay {
    right: 0;
    opacity: .5; }
  .mobile-menu--contact {
    padding-left: 20px; } }

@media (min-width: 1385px) {
  .mobile-menu-top,
  .mobile-menu-bottom {
    display: none; }
  .main-nav input {
    display: none; }
  .main-nav ul {
    padding: 0;
    margin: 0;
    list-style-type: none; }
  .main-nav .btn {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    background: none;
    border-color: white;
    font-size: 13px; }
    .main-nav .btn:hover {
      color: #6E110C;
      background: white;
      border-color: white; }
  ul.main-nav--first-level li {
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;
    position: relative; }
  ul.main-nav--first-level > li:last-child {
    padding-right: 0; }
  ul.main-nav--first-level a {
    display: block;
    line-height: 70px;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    position: relative; }
  ul.main-nav--sub {
    position: absolute;
    top: 100%;
    left: -15px;
    min-width: 180px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #6E110C;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.08);
    text-align: left;
    z-index: 1;
    border-radius: 20px; }
    ul.main-nav--sub li {
      display: block;
      position: relative;
      transition: background .33s ease-in-out;
      padding: 0; }
      ul.main-nav--sub li.main-nav--sub-active, ul.main-nav--sub li:hover {
        background: #400a07; }
    ul.main-nav--sub a {
      display: block;
      line-height: 21px;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 30px;
      padding-right: 35px;
      font-weight: 500;
      color: white; }
  ul.main-nav--sub {
    transform: translateY(1.66667vw);
    opacity: 0;
    transition: all .33s ease-in-out;
    visibility: hidden; }
  ul.main-nav--first-level li:hover > ul.main-nav--sub, ul.main-nav--first-level li:focus-within > ul.main-nav--sub {
    visibility: visible;
    transform: translateY(0);
    opacity: 1; }
  ul.main-nav--first-level > li:last-child ul.main-nav--sub, ul.main-nav--first-level > li:nth-last-child(2) ul.main-nav--sub {
    left: auto;
    right: 15px; }
  .main-nav .main-nav--first-level > li > .plusminus {
    display: none; }
  .main-nav .main-nav--first-level > li .next {
    width: unset;
    height: unset;
    border-radius: unset;
    display: block;
    background: none; }
    .main-nav .main-nav--first-level > li .next .next--arrow {
      border-width: unset;
      border-color: unset;
      border-style: unset;
      margin: 0;
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #6E110C;
      margin-bottom: -3px;
      top: calc(100% - 7px);
      left: 50%;
      transform: translateY(1.66667vw) translateX(-50%);
      opacity: 0;
      transition: all .33s ease-in-out; }
  .main-nav .main-nav--first-level > li:hover .next--arrow, .main-nav .main-nav--first-level > li:focus-within .next--arrow {
    transform: translateY(0) translateX(-50%);
    opacity: 1; } }

@media (min-width: 1385px) {
  .main-nav .main-nav--first-level a {
    display: block;
    position: relative; }
  .main-nav .main-nav--first-level a::after {
    content: '';
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: #6E110C;
    opacity: 0;
    transition: opacity 300ms, transform 300ms; }
  .main-nav .main-nav--first-level a > :hover::after,
  .main-nav .main-nav--first-level a > :focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0); }
  .main-nav .main-nav--first-level li > a::after {
    opacity: 1;
    transform: scale(0);
    transform-origin: center; }
  .main-nav .main-nav--first-level li > a:hover::after,
  .main-nav .main-nav--first-level li > a:focus::after {
    transform: scale(1); }
  .main-nav .main-nav--first-level .desktop-down-hidden a::after {
    display: none; }
  .main-nav .main-nav--first-level .main-nav--sub a::after {
    display: none; } }

/* Fixed Navigation */
/* All */
.page-header--nav:before {
  content: '';
  width: 100vw;
  background: white;
  position: fixed;
  left: 0;
  z-index: 9999;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  transition: opacity ease-in-out .375s, transform ease-in-out .375s;
  top: 0;
  opacity: 0; }

.main-nav--fixed .page-header--nav:before {
  transform: translateY(0);
  transition-delay: 0s;
  opacity: 1; }

/* Mobile */
@media (max-width: 767.98px) {
  ul.main-nav--first-level > li > a {
    transition: color ease-in-out .175s; }
  .main-nav--burger {
    top: 1px; }
  .page-header--nav:before {
    height: 13.33333vw;
    transform: translateY(-13.33333vw); }
  .main-nav--fixed .main-nav--burger {
    position: fixed; }
    .main-nav--fixed .main-nav--burger span {
      background: #333333; }
      .main-nav--fixed .main-nav--burger span:before, .main-nav--fixed .main-nav--burger span:after {
        background: #333333; } }

/* Tablet & Desktop */
@media (min-width: 768px) {
  .main-nav {
    position: relative;
    z-index: 10000; }
    .main-nav .btn {
      transition: all ease-in-out .175s; }
  ul.main-nav--first-level > li > a {
    transition: color ease-in-out .175s; }
  .page-header--nav {
    position: relative; }
  .main-nav--fixed .main-nav {
    position: fixed;
    top: 0;
    left: 0; }
    .main-nav--fixed .main-nav ul.main-nav--first-level {
      max-width: 1630px;
      margin: 0 auto; } }

/* Tablet */
@media (min-width: 768px) and (max-width: 1384.98px) {
  .main-nav--top {
    height: 50px;
    overflow: visible; }
  .main-nav--burger {
    top: 0;
    right: 0;
    transition: none; }
  .page-header--nav:before {
    height: 50px;
    transform: translateY(-50px); }
  .main-nav--fixed .main-nav--toggle:checked ~ .main-nav--top,
  .main-nav--fixed .main-nav--top {
    position: fixed; }
  .main-nav--fixed .main-nav--burger {
    top: 0;
    right: 30px; }
    .main-nav--fixed .main-nav--burger span {
      background: #333333; }
      .main-nav--fixed .main-nav--burger span:before, .main-nav--fixed .main-nav--burger span:after {
        background: #333333; }
  .main-nav--fixed .main-nav {
    left: auto;
    top: 49px; }
  .main-nav--fixed .main-nav--toggle:checked ~ .main-nav--top .main-nav--top .main-nav--burger span {
    color: transparent; } }

/* Desktop */
@media (min-width: 1385px) {
  .page-header--nav:before {
    height: 70px;
    transform: translateY(-70px); }
  .main-nav--fixed .page-header--nav {
    transition: all ease-in-out .375s; }
  .main-nav--fixed .main-nav {
    width: 100%;
    transition: all ease-in-out .33s; }
    .main-nav--fixed .main-nav ul.main-nav--first-level {
      padding: 0 30px; }
      .main-nav--fixed .main-nav ul.main-nav--first-level > li > a {
        color: #333333; }
    .main-nav--fixed .main-nav .btn {
      border-color: #333333; } }

/* page-header versions */
/*

  Version1: Standard
  Version2: white bg for meta, logo, nav
  Version3: white bg for meta, cta and widgets centered
  Version4: cta and widgets centered
  Version5: cta and widgets centered, meta at the bottom
  Version6: white bg for centered logo and nav, meta bottom + white bg
  Version7: white bg for meta, centered logo and nav

 */
/* Version 2 */
@media (max-width: 767.98px) {
  .page-header--1:after {
    content: '';
    width: 100%;
    height: 41.86667vw;
    background: white;
    position: absolute;
    top: 0;
    left: 0; }
  .page-header--1 .page-header--logo .logo {
    background-image: svg-load("../_images/svg/Logo_Altenberger_2021.svg"); }
  .page-header--1 .page-header--contact a {
    color: #333333; }
  .page-header--1 .main-nav--burger span {
    background: #333333; }
    .page-header--1 .main-nav--burger span:before, .page-header--1 .main-nav--burger span:after {
      background: #333333; }
  .page-header--1 .page-header--image img {
    height: calc(100% - 41.86667vw);
    top: auto;
    bottom: 0; }
  .page-header--1 .page-header--image:after {
    height: 100%; }
  .page-header--1 .page-header--widgets {
    padding: 0 8vw; } }

@media (min-width: 768px) and (max-width: 1384.98px) {
  .page-header--1 .main-nav--burger {
    top: 20px; }
    .page-header--1 .main-nav--burger span {
      background: #333333; }
      .page-header--1 .main-nav--burger span:before, .page-header--1 .main-nav--burger span:after {
        background: #333333; } }

@media (min-width: 768px) {
  .page-header--1:after {
    content: '';
    width: 100%;
    height: 200px;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 51; }
  .page-header--1 .page-header--meta {
    border-bottom: 1px solid rgba(51, 51, 51, 0.2); }
    .page-header--1 .page-header--meta .page-header--contact a,
    .page-header--1 .page-header--meta .page-header--social a,
    .page-header--1 .page-header--meta .page-header--language a {
      color: #333333; }
  .page-header--1 .page-header--language li a {
    border-left: 1px solid rgba(51, 51, 51, 0.2); } }

@media (min-width: 1385px) {
  .page-header--1 ul.main-nav--first-level > li > a {
    color: #333333; }
  .page-header--1 .main-nav ul.main-nav--first-level > li > a.btn {
    border-color: #333333;
    color: #333333; }
    .page-header--1 .main-nav ul.main-nav--first-level > li > a.btn:hover {
      border-color: #6E110C;
      background: #6E110C;
      color: white; } }

/* Version 2 */
@media (max-width: 767.98px) {
  .page-header--2:after {
    content: '';
    width: 100%;
    height: 13.33333vw;
    background: white;
    position: absolute;
    top: 0;
    left: 0; }
  .page-header--2 .page-header--logo .logo {
    background-image: svg-load("../_images/svg/logo-white.svg"); }
  .page-header--2 .page-header--contact a {
    color: #333333; }
  .page-header--2 .main-nav--burger span {
    background: #333333; }
    .page-header--2 .main-nav--burger span:before, .page-header--2 .main-nav--burger span:after {
      background: #333333; }
  .page-header--2 .page-header--image img {
    height: calc(100% - 13.33333vw);
    top: auto;
    bottom: 0; }
  .page-header--2 .page-header--image:after {
    height: 100%; }
  .page-header--2 .page-header--widgets {
    padding: 0 8vw; } }

@media (min-width: 768px) {
  .page-header--2:after {
    content: '';
    width: 100%;
    height: 49px;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 51; }
  .page-header--2 .page-header--meta {
    border-bottom: 1px solid rgba(51, 51, 51, 0.2); }
    .page-header--2 .page-header--meta .page-header--contact a,
    .page-header--2 .page-header--meta .page-header--social a,
    .page-header--2 .page-header--meta .page-header--language a {
      color: #333333; }
  .page-header--2 .page-header--language li a {
    border-left: 1px solid rgba(51, 51, 51, 0.2); }
  .page-header--2 .page-header--boxed {
    grid-template-areas: 'logo nav' 'widgets widgets'; }
  .page-header--2 .page-header--cta {
    position: absolute;
    bottom: 280px; }
    .page-header--2 .page-header--cta h1 {
      width: 100%;
      text-align: center; } }

@media (min-width: 768px) and (min-width: 768px) and (max-width: 1199.98px) {
  /* Page Header Boxed */
  .page-header--boxed {
    padding-bottom: 30px; }
  /* Booking Widget */
  .page-header--2 .page-header--widgets {
    padding: 0; }
  .page-header--2 .page-header--booking {
    max-width: 600px;
    margin: 0 auto;
    padding: 0; }
    .page-header--2 .page-header--booking form {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'dates dates button' 'numbers numbers numbers'; }
      .page-header--2 .page-header--booking form .booking-dates {
        grid-area: dates; }
      .page-header--2 .page-header--booking form .booking-numbers {
        grid-area: numbers; }
      .page-header--2 .page-header--booking form .booking-button {
        grid-area: button; }
  .page-header--2 .booking-dates {
    grid-column-gap: 8vw;
    grid-column-gap: 20px;
    padding: 20px; } }
  @media (min-width: 768px) and (min-width: 768px) and (max-width: 1199.98px) and (min-width: 768px) {
    .page-header--2 .booking-dates {
      grid-column-gap: 30px; } }

@media (min-width: 768px) and (min-width: 768px) and (max-width: 1199.98px) {
  .page-header--2 .booking-button {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .page-header--2 .booking-numbers {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr); }
  .page-header--2 .form-element.booking-element--numbers {
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px; }
    .page-header--2 .form-element.booking-element--numbers label {
      line-height: 14px;
      margin-bottom: 7px; }
  .page-header--2 .booking-button {
    padding-bottom: 20px;
    display: flex; }
    .page-header--2 .booking-button .btn {
      align-self: flex-end;
      width: 100%; } }

@media (min-width: 1200px) {
  /* Booking Widget */
  .page-header--2 .page-header--booking {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0; }
    .page-header--2 .page-header--booking form {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(3, auto); }
  .page-header--2 .booking-dates {
    border-bottom: none;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    grid-column-gap: 30px;
    padding: 30px; }
  .page-header--2 .booking-numbers {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr); }
  .page-header--2 .form-element.booking-element--numbers {
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30px 30px 0; }
    .page-header--2 .form-element.booking-element--numbers label {
      line-height: 14px;
      margin-bottom: 7px; }
  .page-header--2 .booking-button {
    padding-bottom: 30px;
    display: flex; }
    .page-header--2 .booking-button .btn {
      align-self: flex-end; } }

/* page-footer */
/* Surrounding container */
.page-footer {
  padding-right: 4vw;
  padding-left: 4vw;
  padding-top: 7.06667vw;
  padding-bottom: 8vw;
  background: #F2F2F2; }
  @media (min-width: 768px) {
    .page-footer {
      padding-right: 0; } }
  @media (min-width: 768px) {
    .page-footer {
      padding-left: 0; } }

/* Address block */
.page-footer--address {
  background: white;
  text-align: center;
  font-size: 3.73333vw;
  line-height: 5.33333vw;
  border-radius: 5.33333vw;
  padding-top: 10.66667vw;
  padding-right: 13.33333vw;
  padding-bottom: 10.66667vw;
  padding-left: 13.33333vw;
  max-width: 85.33333vw;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 11.06667vw; }
  @media (min-width: 768px) {
    .page-footer--address {
      font-size: 14px; } }
  @media (min-width: 768px) {
    .page-footer--address {
      line-height: 20px; } }
  @media (min-width: 768px) {
    .page-footer--address {
      border-radius: 20px; } }
  @media (min-width: 768px) {
    .page-footer--address {
      padding-top: 80px; } }
  @media (min-width: 768px) {
    .page-footer--address {
      padding-right: 50px; } }
  @media (min-width: 768px) {
    .page-footer--address {
      padding-bottom: 80px; } }
  @media (min-width: 768px) {
    .page-footer--address {
      padding-left: 50px; } }
  @media (min-width: 768px) {
    .page-footer--address {
      max-width: 370px; } }
  .page-footer--address > div:not(.page-footer--address-logo) {
    margin-bottom: 5.33333vw; }
    @media (min-width: 768px) {
      .page-footer--address > div:not(.page-footer--address-logo) {
        margin-bottom: 20px; } }
  .page-footer--address a:not(.btn) {
    color: black; }

.page-footer--address-logo {
  width: 42.13333vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5.33333vw; }
  @media (min-width: 768px) {
    .page-footer--address-logo {
      width: 158px; } }
  @media (min-width: 768px) {
    .page-footer--address-logo {
      margin-bottom: 40px; } }

/* Menus */
.footer-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.footer-menu li {
  margin-bottom: 4vw;
  line-height: 6.66667vw; }
  @media (min-width: 768px) {
    .footer-menu li {
      margin-bottom: 15px; } }
  @media (min-width: 768px) {
    .footer-menu li {
      line-height: 25px; } }

/* Newsletter Signup */
.footer-newsletter {
  text-align: center;
  margin-bottom: 13.33333vw; }
  @media (min-width: 768px) {
    .footer-newsletter {
      text-align: left; } }
  @media (min-width: 768px) {
    .footer-newsletter {
      margin-bottom: 0; } }
  .footer-newsletter input[type=email] {
    margin-bottom: 5.33333vw; }
    @media (min-width: 768px) {
      .footer-newsletter input[type=email] {
        margin-bottom: 0; } }
  .footer-newsletter label {
    text-align: left;
    font-size: 3.73333vw;
    line-height: 5.33333vw;
    margin-bottom: 6.13333vw;
    display: grid;
    grid-template-columns: 8vw auto; }
    @media (min-width: 768px) {
      .footer-newsletter label {
        font-size: 14px; } }
    @media (min-width: 768px) {
      .footer-newsletter label {
        line-height: 20px; } }
    @media (min-width: 768px) {
      .footer-newsletter label {
        grid-template-columns: 30px auto; } }

/* Footer Gallery */
.footer--gallery-grid {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 170px));
  grid-column-gap: 32px; }
  .footer--gallery-grid > a {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%; }
    .footer--gallery-grid > a img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .footer--gallery-grid > a:hover .overlay {
      opacity: 1; }
  .footer--gallery-grid .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(110, 17, 12, 0.8);
    transition: opacity ease-in-out .3s;
    opacity: 0;
    display: grid;
    place-content: center; }
  .footer--gallery-grid i {
    color: white;
    font-size: 30px; }

/* Bottom Element - Copyright and Conecto */
.footer--bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 4vw;
  font-size: 3.73333vw;
  line-height: 5.33333vw; }
  @media (min-width: 768px) {
    .footer--bottom {
      padding-top: 25px; } }
  @media (min-width: 768px) {
    .footer--bottom {
      font-size: 14px; } }
  @media (min-width: 768px) {
    .footer--bottom {
      line-height: 20px; } }
  .footer--bottom .logo--flat {
    margin-top: 1.33333vw; }
    @media (min-width: 768px) {
      .footer--bottom .logo--flat {
        margin-top: 5px; } }

.copyright {
  opacity: .4;
  text-transform: uppercase; }

/* ----------------- */
/* Footer Grid & Different Sizes */
.footer--address {
  grid-area: address; }

.footer--menus {
  grid-area: menus; }

.footer--widgets {
  grid-area: widgets; }

.footer--info {
  grid-area: info; }

.footer--grid {
  display: grid;
  grid-template-areas: 'address' 'menus' 'widgets' 'info'; }

@media (max-width: 767.98px) {
  .footer--menus {
    padding-left: 8vw;
    padding-right: 8vw; }
  .footer-menu {
    padding-bottom: 8vw; }
  .footer--widgets {
    padding-left: 8vw;
    padding-right: 8vw;
    padding-bottom: 10.66667vw; }
  .footer--bottom {
    padding-left: 8vw;
    padding-right: 8vw;
    text-align: center; } }

@media (min-width: 768px) {
  .page-footer--address {
    margin-bottom: 0; }
  .footer--menus {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
  .footer--grid {
    grid-row-gap: 50px; }
  .footer--bottom {
    text-align: right; } }

@media (min-width: 880px) {
  /*.footer--grid:not(.is-empty) {
    grid-template-columns: 25% 25% 50%;
    grid-template-areas:
    'address address address'
    'menus menus widgets'
    'info info info';
  }*/ }

@media (min-width: 992px) {
  .footer--grid {
    grid-template-columns: 400px 1fr 1fr;
    grid-template-areas: 'address menus menus' 'address widgets widgets' 'info info info'; }
    .footer--grid.is-empty {
      grid-template-areas: 'address . .' 'address menus menus' 'address . .' 'info info info'; }
  .page-footer--address {
    margin: 0; }
  .footer-grid.is-empty .page-footer--address {
    margin: 0 auto; } }

/*@include media-breakpoint-up(lgl) {
  .footer--grid {
    grid-template-columns: 450px 1fr 1fr;
  }
}*/
@media (min-width: 1385px) {
  .footer--grid {
    grid-template-areas: 'address . .' 'address menus widgets' 'address menus widgets' 'address info info' 'address . .'; } }

@media (min-width: 1630px) {
  .page-footer {
    padding-top: 155px;
    padding-bottom: 140px; }
  .footer--grid {
    grid-template-columns: 534px 1fr 1fr;
    grid-template-areas: 'address . .' 'address menus widgets' 'address menus widgets' 'address info info' 'address . .'; }
    .footer--grid.is-empty {
      grid-template-areas: 'address . .' 'address menus menus' 'address menus menus' 'address info info' 'address . .'; } }

@media (min-width: 768px) {
  /* Newsletter Sign-up */
  .footer--newsletter input[type=checkbox] {
    margin-top: 0.8vw; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .footer--newsletter input[type=checkbox] {
      margin-top: 3px; } }

@media (min-width: 768px) {
  .footer--newsletter .newsletter-button {
    display: flex; }
    .footer--newsletter .newsletter-button button {
      align-self: flex-end; }
  .footer--newsletter form {
    display: grid;
    grid-template-columns: 8fr 2fr;
    row-gap: 1.22699vw;
    column-gap: 1.22699vw;
    grid-template-areas: "input button" "label x"; } }
  @media (min-width: 768px) and (min-width: 1630px) {
    .footer--newsletter form {
      row-gap: 20px; } }
  @media (min-width: 768px) and (min-width: 1630px) {
    .footer--newsletter form {
      column-gap: 20px; } }

@media (min-width: 768px) {
  .footer--newsletter .newsletter--input {
    grid-area: input; }
  .footer--newsletter .newsletter--button {
    grid-area: button; }
  .newsletter--label {
    grid-area: label; }
    .newsletter--label label {
      grid-template-columns: 2.5vw auto; } }
    @media (min-width: 768px) and (min-width: 1200px) {
      .newsletter--label label {
        grid-template-columns: 30px auto; } }

/* content-elements elements */
.contentblock h1, .contentblock h2, .contentblock h3, .contentblock h4, .contentblock h5 {
  font-weight: 900; }

.contentblock h1 {
  font-size: 10.4vw;
  line-height: 12.26667vw; }
  @media (min-width: 768px) {
    .contentblock h1 {
      font-size: 48px; } }
  @media (min-width: 768px) {
    .contentblock h1 {
      line-height: 56px; } }

.contentblock h2 {
  font-size: 10.4vw;
  line-height: 12.26667vw; }
  @media (min-width: 768px) {
    .contentblock h2 {
      font-size: 39px; } }
  @media (min-width: 768px) {
    .contentblock h2 {
      line-height: 46px; } }

.contentblock h3 {
  font-size: 8.26667vw;
  line-height: 9.6vw; }
  @media (min-width: 768px) {
    .contentblock h3 {
      font-size: 31px; } }
  @media (min-width: 768px) {
    .contentblock h3 {
      line-height: 36px; } }

.contentblock h4 {
  font-size: 6.66667vw;
  line-height: 7.73333vw; }
  @media (min-width: 768px) {
    .contentblock h4 {
      font-size: 25px; } }
  @media (min-width: 768px) {
    .contentblock h4 {
      line-height: 29px; } }

.contentblock h5 {
  font-size: 5.33333vw;
  line-height: 6.13333vw; }
  @media (min-width: 768px) {
    .contentblock h5 {
      font-size: 20px; } }
  @media (min-width: 768px) {
    .contentblock h5 {
      line-height: 23px; } }

.contentblock--largeteaser .largeteaser {
  margin-left: -4vw;
  margin-right: -4vw;
  display: block; }
  @media (min-width: 768px) {
    .contentblock--largeteaser .largeteaser {
      margin-left: 0; } }
  @media (min-width: 768px) {
    .contentblock--largeteaser .largeteaser {
      margin-right: 0; } }
  .contentblock--largeteaser .largeteaser span {
    display: block; }
  .contentblock--largeteaser .largeteaser a {
    color: black; }
  .contentblock--largeteaser .largeteaser h3 {
    font-size: 4.8vw;
    line-height: 5.6vw;
    margin-bottom: 5.33333vw; }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser h3 {
        font-size: 25px; } }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser h3 {
        line-height: 29px; } }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser h3 {
        margin-bottom: 32px; } }
  .contentblock--largeteaser .largeteaser .arrow-link {
    position: absolute;
    bottom: 7.28267vw;
    right: 8vw; }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser .arrow-link {
        bottom: 67.17px; } }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser .arrow-link {
        right: 60px; } }
    .contentblock--largeteaser .largeteaser .arrow-link i {
      margin-right: 2.66667vw; }
      @media (min-width: 768px) {
        .contentblock--largeteaser .largeteaser .arrow-link i {
          margin-right: 10px; } }
  .contentblock--largeteaser .largeteaser .arrow-right {
    width: 16vw;
    height: 16vw;
    left: -8vw;
    top: 8vw;
    background: #6E110C;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser .arrow-right {
        width: 100px; } }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser .arrow-right {
        height: 100px; } }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser .arrow-right {
        left: -50px; } }
    @media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser .arrow-right {
        top: 60px; } }
    .contentblock--largeteaser .largeteaser .arrow-right i {
      font-size: 4.53333vw;
      color: white; }
      @media (min-width: 768px) {
        .contentblock--largeteaser .largeteaser .arrow-right i {
          font-size: 26px; } }

.largeteaser--image {
  background: #6E110C; }
  .largeteaser--image img, .largeteaser--image video {
    width: 100%;
    height: auto; }

.largeteaser--content {
  margin-top: -16vw;
  padding-left: 12vw;
  padding-right: 6.66667vw;
  position: relative;
  z-index: 1; }
  @media (min-width: 768px) {
    .largeteaser--content {
      margin-top: -80px; } }
  @media (min-width: 768px) {
    .largeteaser--content {
      padding-left: 80px; } }
  @media (min-width: 768px) {
    .largeteaser--content {
      padding-right: 25px; } }

.largeteaser--content-container {
  position: relative;
  background: white;
  border-radius: 5.33333vw;
  color: black;
  padding-top: 8vw;
  padding-right: 8vw;
  padding-bottom: 18.216vw;
  padding-left: 16vw;
  /*
    padding-top: 60px;
    padding-right: 60px;
    padding-bottom: 100px;
    padding-left: 90px;
   */ }
  @media (min-width: 768px) {
    .largeteaser--content-container {
      border-radius: 20px; } }
  @media (min-width: 768px) {
    .largeteaser--content-container {
      padding-top: 96px; } }
  @media (min-width: 768px) {
    .largeteaser--content-container {
      padding-right: 60px; } }
  @media (min-width: 768px) {
    .largeteaser--content-container {
      padding-bottom: 122.17px; } }
  @media (min-width: 768px) {
    .largeteaser--content-container {
      padding-left: 103px; } }

@media (min-width: 768px) {
  .contentblock--largeteaser .largeteaser--image {
    width: 100%;
    height: 0;
    padding-bottom: 56.2548%;
    position: relative; }
    .contentblock--largeteaser .largeteaser--image img, .contentblock--largeteaser .largeteaser--image video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .contentblock--largeteaser .largeteaser {
    display: grid; } }
  @media (min-width: 768px) and (min-width: 1200px) {
    .contentblock--largeteaser .largeteaser {
      grid-template-columns: 82fr 18fr; } }

@media (min-width: 768px) {
    .contentblock--largeteaser .largeteaser.largeteaser--image-right {
      direction: rtl; }
      .contentblock--largeteaser .largeteaser.largeteaser--image-right .largeteaser--content {
        margin-left: auto; } }
      @media (min-width: 768px) and (min-width: 1200px) {
        .contentblock--largeteaser .largeteaser.largeteaser--image-right .largeteaser--content {
          margin-right: -10.7362vw;
          margin-right: -175px; } }
  @media (min-width: 768px) and (min-width: 1200px) and (min-width: 1630px) {
    .contentblock--largeteaser .largeteaser.largeteaser--image-right .largeteaser--content {
      margin-right: -175px; } }

@media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser.largeteaser--image-right .largeteaser--content-container {
        padding-left: 3.68098vw;
        padding-right: 6.31902vw;
        direction: ltr; } }
      @media (min-width: 768px) and (min-width: 1630px) {
        .contentblock--largeteaser .largeteaser.largeteaser--image-right .largeteaser--content-container {
          padding-left: 60px; } }
      @media (min-width: 768px) and (min-width: 1630px) {
        .contentblock--largeteaser .largeteaser.largeteaser--image-right .largeteaser--content-container {
          padding-right: 103px; } }

@media (min-width: 768px) {
      .contentblock--largeteaser .largeteaser.largeteaser--image-right .arrow-right {
        left: auto;
        right: -3.06748vw; } }
      @media (min-width: 768px) and (min-width: 1630px) {
        .contentblock--largeteaser .largeteaser.largeteaser--image-right .arrow-right {
          right: -50px; } }

@media (min-width: 768px) {
  .contentblock--largeteaser .largeteaser--content {
    margin-top: -80px;
    padding-left: 80px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center; } }
  @media (min-width: 768px) and (min-width: 1200px) {
    .contentblock--largeteaser .largeteaser--content {
      margin-left: -10.7362vw;
      margin-top: 0;
      margin-left: -175px;
      padding: 0; } }
  @media (min-width: 768px) and (min-width: 1200px) and (min-width: 1630px) {
    .contentblock--largeteaser .largeteaser--content {
      margin-left: -175px; } }

@media (max-width: 767.98px) {
  .contentblock--teaserslider {
    /*padding: 0;*/
    margin-top: -8vw;
    /*padding-left: pxToVwMobile(15px);
    padding-right: pxToVwMobile(15px);*/
    position: relative; }
    .contentblock--teaserslider .container {
      padding: 0; }
    .contentblock--teaserslider .splide__track {
      padding-top: 8vw;
      padding-bottom: 8vw; } }

@media (min-width: 768px) {
  .contentblock--teaserslider .splide__track {
    padding-top: 20px;
    padding-bottom: 20px; }
  .contentblock--teaserslider .teaser {
    max-width: unset;
    height: 100%; }
  .contentblock--teaserslider .box-shadow {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.075); }
  .contentblock--teaserslider .teaserslider--controls .prev,
  .contentblock--teaserslider .teaserslider--controls .next {
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }
  .contentblock--teaserslider .teaserslider--controls .prev {
    left: 0; }
  .contentblock--teaserslider .teaserslider--controls .next {
    right: 0; }
  /*.contentblock--teaserslider {
    .tns-ovh {
      overflow: visible;
    }
    .teaserslider--container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include fluid(grid-column-gap, 30px);
      grid-column-gap: 30px;
    }
    .teaserslider--controls {
      display: none;
    }
  }*/ }

.contentblock--textbox .textbox {
  background: #6E110C;
  color: white; }

.contentblock--textbox img, .contentblock--textbox video {
  width: 100%;
  height: auto; }

.contentblock--textbox a {
  color: white; }

@media (min-width: 768px) {
  .contentblock--textbox .textbox--image {
    position: relative;
    height: 100%; }
    .contentblock--textbox .textbox--image img, .contentblock--textbox .textbox--image video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

.contentblock--textbox .textbox--content-container {
  text-align: center;
  padding-top: 10.66667vw;
  padding-right: 9.528vw;
  padding-bottom: 10.66667vw;
  padding-left: 9.528vw; }
  @media (min-width: 768px) {
    .contentblock--textbox .textbox--content-container {
      text-align: left; } }
  @media (min-width: 768px) {
    .contentblock--textbox .textbox--content-container {
      padding: 10% 10% 16% 10%; } }

.contentblock--textbox h2, .contentblock--textbox h3 {
  font-weight: 900;
  margin-bottom: 5.33333vw; }
  @media (min-width: 768px) {
    .contentblock--textbox h2, .contentblock--textbox h3 {
      margin-bottom: 5%; } }

.contentblock--textbox h3 {
  font-size: 5.86667vw;
  line-height: 6.93333vw; }
  @media (min-width: 768px) {
    .contentblock--textbox h3 {
      font-size: 31px; } }
  @media (min-width: 768px) {
    .contentblock--textbox h3 {
      line-height: 36px; } }

.contentblock--textbox p {
  margin-bottom: 5.33333vw; }
  @media (min-width: 768px) {
    .contentblock--textbox p {
      margin-bottom: 5%; } }

.contentblock--textbox ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 8vw; }
  @media (min-width: 768px) {
    .contentblock--textbox ul {
      margin-bottom: 30px; } }
  .contentblock--textbox ul li {
    padding-left: 5.33333vw;
    position: relative; }
    @media (min-width: 768px) {
      .contentblock--textbox ul li {
        padding-left: 20px; } }
    .contentblock--textbox ul li:before {
      content: "";
      width: 6px;
      height: 6px;
      background: white;
      position: absolute;
      left: 0;
      top: 9px; }

.contentblock--textbox a.btn--outline-negative:hover {
  border-color: white;
  background: white;
  color: #6E110C; }

@media (min-width: 768px) {
  .textbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    .textbox.textbox--image-right {
      direction: rtl; }
      .textbox.textbox--image-right * {
        direction: ltr; } }

.contentblock--wysiwyg p {
  font-size: 4vw;
  line-height: 6.13333vw; }
  @media (min-width: 768px) {
    .contentblock--wysiwyg p {
      font-size: 16px; } }
  @media (min-width: 768px) {
    .contentblock--wysiwyg p {
      line-height: 25px; } }

.contentblock--wysiwyg a {
  color: black;
  text-decoration: underline;
  font-weight: 500; }
  .contentblock--wysiwyg a[target="_blank"] {
    font-weight: 400; }

.contentblock--wysiwyg ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .contentblock--wysiwyg ul li {
    position: relative;
    padding-left: 20px; }
    .contentblock--wysiwyg ul li:before {
      content: '';
      width: 6px;
      height: 6px;
      background: #6E110C;
      position: absolute;
      left: 0;
      top: 9px; }

.contentblock--wysiwyg-lead p {
  font-size: 4.53333vw;
  line-height: 6.13333vw;
  font-weight: 500; }
  @media (min-width: 768px) {
    .contentblock--wysiwyg-lead p {
      font-size: 19px; } }
  @media (min-width: 768px) {
    .contentblock--wysiwyg-lead p {
      line-height: 26px; } }

.gallery--container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }

.gallery--image {
  display: block; }
  .gallery--image img {
    width: 100%;
    height: auto; }

.gallery--container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px; }

.contentblock--gallery-large .gallery--container {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px; }

@media (min-width: 768px) {
  .gallery--container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5vw; }
    .gallery--container > a {
      flex-grow: 1;
      width: calc(25% - 2em);
      max-width: calc(25% - 2em); } }

@media (min-width: 992px) {
  .gallery--container {
    gap: 30px; } }

/* Tabelle */
.contentblock--table-responsive {
  width: 100%;
  overflow-x: auto; }

.contentblock--table {
  font-size: 16px;
  line-height: 25px; }
  .contentblock--table table {
    width: 100%;
    border: none; }
  .contentblock--table th {
    background: #6E110C;
    color: white;
    border: none; }
  .contentblock--table th, .contentblock--table td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border: none; }
  .contentblock--table td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

/* Grid */
.grid {
  max-width: 1920px;
  margin: 0 auto; }
  .grid .grid--image {
    min-height: 360px; }
  .grid .contentblock {
    margin: 0; }
  .grid .contentblock--textbox .textbox--content {
    display: grid;
    place-items: center; }
  .grid .contentblock--textbox .textbox--content-container {
    text-align: center;
    padding: 10%; }
    .grid .contentblock--textbox .textbox--content-container a:not(.btn) {
      color: white;
      text-decoration: underline; }

@media (max-width: 767.98px) {
  .contentblock--grid .container--wide {
    padding-left: 0;
    padding-right: 0; }
  .grid--slider {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth; }
    .grid--slider .textbox--image {
      height: 56.8vw; }
  .grid--slider-element {
    width: 76vw;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 100%;
    scroll-snap-align: start; } }

@media (min-width: 768px) {
  .grid--slider .grid--slider-element {
    direction: ltr; }
    .grid--slider .grid--slider-element:nth-child(odd) {
      direction: rtl; }
      .grid--slider .grid--slider-element:nth-child(odd) .textbox--content-container {
        direction: ltr; }
  .grid--slider .contentblock--textbox .textbox--content {
    min-height: 360px; } }

@media (min-width: 1385px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, minmax(360px, auto));
    grid-template-areas: 'image-large slider-1' 'image-large slider-2' 'slider-3 image-wide'; }
  .grid--slider {
    display: contents; }
    .grid--slider .grid--slider-element:nth-child(3) {
      direction: ltr; } }

.grid--image {
  position: relative; }
  .grid--image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.grid--image-large {
  grid-area: image-large; }

.slider-element--1 {
  grid-area: slider-1; }

.slider-element--2 {
  grid-area: slider-2; }

.slider-element--3 {
  grid-area: slider-3; }

.grid--image-wide {
  grid-area: image-wide; }

.contentblock--divider hr {
  border: none;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1); }

.contentblock.contentblock--divider {
  margin-bottom: 24vw; }
  @media (min-width: 768px) {
    .contentblock.contentblock--divider {
      margin-bottom: 90px; } }

.disruptor--image {
  position: relative; }
  .disruptor--image figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 28.125%;
    background-color: rgba(110, 17, 12, 0.2); }
  .disruptor--image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .disruptor--image .centered {
    width: 100%;
    position: absolute;
    z-index: 1;
    text-align: center;
    top: 50%;
    transform: translateY(-50%); }
  .disruptor--image h2 {
    margin-bottom: 30px;
    color: white; }

@media (max-width: 879.98px) {
  .disruptor--image figure {
    padding-bottom: 50%; }
  .disruptor--image h2 {
    font-size: 8.26667vw;
    line-height: 9.6vw;
    margin-bottom: 5.33333vw; } }

@media (max-width: 767.98px) {
  .mobile-hidden {
    display: none !important; } }

@media (max-width: 1384.98px) {
  .desktop-down-hidden {
    display: none !important; } }

video {
  background-color: #400a07; }

.contentblock--image img {
  width: 100%;
  height: auto; }

.contentblock--image .image-video {
  width: 100%;
  position: relative; }
  .contentblock--image .image-video video {
    /*position: absolute;
      top: 0;
      left: 0;*/
    width: 100%;
    height: auto; }

/* Contact Map */
.contact_map {
  height: 50vh;
  z-index: 1;
  overflow: hidden; }

/* Alert */
.alert {
  padding: 8vw;
  border-width: 0.53333vw;
  border-style: solid;
  border-color: #6E110C;
  border-radius: 5.33333vw;
  background: rgba(110, 17, 12, 0.2);
  color: #6E110C;
  text-align: center;
  margin-bottom: 8vw; }
  @media (min-width: 768px) {
    .alert {
      padding: 30px; } }
  @media (min-width: 768px) {
    .alert {
      border-width: 2px; } }
  @media (min-width: 768px) {
    .alert {
      border-radius: 20px; } }
  @media (min-width: 768px) {
    .alert {
      margin-bottom: 30px; } }

/* Preload */
.preload * {
  transition: none !important; }

body {
  overflow-x: hidden; }

/* Set border-radius to 0 if body has class 'no-rounded-borders' (set via property) */
.no-rounded-borders ul.main-nav--sub,
.no-rounded-borders .largeteaser--content-container,
.no-rounded-borders .contentblock .teaser,
.no-rounded-borders .teaser,
.no-rounded-borders .btn,
.no-rounded-borders .prev, .no-rounded-borders .next,
.no-rounded-borders input[type=email],
.no-rounded-borders input[type=text],
.no-rounded-borders input[type=date],
.no-rounded-borders input[type=password],
.no-rounded-borders select,
.no-rounded-borders textarea,
.no-rounded-borders .form-column button,
.no-rounded-borders .page-footer--address {
  border-radius: 0vw; }
  @media (min-width: 768px) {
    .no-rounded-borders ul.main-nav--sub,
    .no-rounded-borders .largeteaser--content-container,
    .no-rounded-borders .contentblock .teaser,
    .no-rounded-borders .teaser,
    .no-rounded-borders .btn,
    .no-rounded-borders .prev, .no-rounded-borders .next,
    .no-rounded-borders input[type=email],
    .no-rounded-borders input[type=text],
    .no-rounded-borders input[type=date],
    .no-rounded-borders input[type=password],
    .no-rounded-borders select,
    .no-rounded-borders textarea,
    .no-rounded-borders .form-column button,
    .no-rounded-borders .page-footer--address {
      border-radius: 0; } }
