$logoWidth: 197px;
$logoHeight: 80px;
$logoRatio: (strip-unit($logoHeight)*100)/strip-unit($logoWidth);

.logo {
  background-image: svg-load('../_images/svg/Logo_Altenberger_2021.svg');
  width: 100%;
  height: 0;
  padding-bottom: $logoRatio + 0%;
  display: block;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999em;
}
.logo--black {
  background-image: svg-load('../_images/svg/Logo_Altenberger_2021.svg');
  background-size: contain;
}
.logo--flat {
  background-image: svg-load('../_images/svg/logo-conecto.svg');
  @include fluid(width, 140px);
  @include fluid(height, 8px);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  color: rgba($black, .4);
  transition: opacity ease-in-out .3s;
  opacity: .4;
  &:hover {
    opacity: 1;
  }
}


