// todo: logo als variable
// todo: headliens als variablen

/* General Border Radius */
$border-radius: 20px; // e.g. for teasers

@import 'variables/fonts';
@import 'variables/colors';
@import 'variables/buttons';

@import 'variables/reboot';




/* Breakpoints */
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        mdl: 880px,
        lg: 992px,
        lgl: 1024px,
        xl: 1200px,
        xll: 1385px,
        xlplus: 1630px,
        outer: 1920px
) !default;

$speaking-grid: (
        mobile: 0,
        mobile_mid: 576px,

        tablet: 768px,
        tablet_tiny: 880px,
        tablet_small: 992px,
        tablet_mid: 1096px,
        tablet_large: 1200px,

        desktop: 1385px,
        desktop_wide: 1630px,
        outer: 1920
) !default;



$maxWidthDesktop: map-get($grid-breakpoints, xl);
$maxWidthDesktopPlus: map-get($grid-breakpoints, xlplus);
$outerLimit: map-get($grid-breakpoints, outer);

/* Page Layout Elements */

$headerHeightMobile: 120vw;
$headerHeightDesktopPortal: 100vh;
$headerHeightDesktopContent: 50vh;

/* Container Paddings & Margins */

$containerMarginMobile: 15px;
$containerMarginDesktop: 30px;


