/* Grid */

.grid {
  max-width: map-get($grid-breakpoints, outer); // todo: remove?
  margin: 0 auto;
  .grid--image {
    min-height: 360px;
  }
  .contentblock {
    margin: 0;
  }
  .contentblock--textbox {
    .textbox--content {
      display: grid;
      place-items: center;
    }
    .textbox--content-container {
      text-align: center;
      padding: 10%;
      a:not(.btn) {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .contentblock--grid {
    .container--wide {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .grid--slider {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    .textbox--image {
      height: pxToVwMobile(213px);
    }
  }
  .grid--slider-element {
    width: pxToVwMobile(285px);
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 100%;
    scroll-snap-align: start;
  }
}

@include media-breakpoint-up(md) {
  .grid--slider {
    .grid--slider-element {
      direction: ltr;
      &:nth-child(odd) {
        direction: rtl;
        .textbox--content-container {
          direction: ltr;
        }
      }
    }
    .contentblock--textbox {
      .textbox--content {
        min-height: 360px;
      }
    }
  }
}

@include media-breakpoint-up(xll) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, minmax(360px, auto));
    grid-template-areas:
    'image-large slider-1'
    'image-large slider-2'
    'slider-3 image-wide';
  }
  .grid--slider {
    display: contents;
    .grid--slider-element {
      &:nth-child(3) {
        direction: ltr;
      }
    }
  }
}

.grid--image {
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.grid--image-large { grid-area: image-large };
.slider-element--1 { grid-area: slider-1 };
.slider-element--2 { grid-area: slider-2 };
.slider-element--3 { grid-area: slider-3 };
.grid--image-wide { grid-area: image-wide };