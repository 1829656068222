/********************
  Buttons
*********************/

/* General - for all buttons */

$btn-height: 60px;
$btn-alt-height: 40px; // used for flat buttons (desktop) and generally for mobile height

$btn-border-width: 2px;
$btn-border-radius: 30px;
$btn-border-style: solid;

$btn-text-transform: uppercase;
$btn-font-weight: $font-weight-bolder;
$btn-font-size-mobile: 13px;
$btn-font-size-desktop: 15px;

$btn-padding-mobile: 25px;
$btn-padding-desktop: 30px;

/* Main Button */

$btn-main-color-bg: $color1;
$btn-main-color-txt: $white;
$btn-main-color-border: $color1;

$btn-main-color-bg-hover: $black;
$btn-main-color-txt-hover: $white;
$btn-main-color-border-hover: $black;

/* Outline Button */

$btn-out-color-bg: transparent;
$btn-out-color-txt: $black;
$btn-out-color-border: $black;

$btn-out-color-bg-hover: $white;
$btn-out-color-txt-hover: $color1;
$btn-out-color-border-hover: $white;

/* Negative Outline Button */

$btn-neg-color-bg: transparent;
$btn-neg-color-txt: $white;
$btn-neg-color-border: $white;

$btn-neg-color-bg-hover: transparent;
$btn-neg-color-txt-hover: $color1;
$btn-neg-color-border-hover: $color1;

/* Negative Outline Button - Variant */

$btn-negvar-color-bg: $white;
$btn-negvar-color-txt: $color1;
$btn-negvar-color-border: $white;

/********************
  Arrow Links
*********************/

$al-color-txt: $colorText;
$al-color-border: $colorBorder;
$al-color-hover-icon: $color1;

/********************
  Navigational Buttons (.prev, .next)
*********************/

$navbtn-size: 46px;
$navbtn-border-radius: 46px;
$navbtn-color-bg: $color1;

$navbtn-arrow-color: $white;
$navbtn-arrow-size: 10px;
$navbtn-arrow-width: 2px;