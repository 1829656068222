/* Surrounding container */

.page-footer {
  @include fluidDiff(padding-right, 15px, 0);
  @include fluidDiff(padding-left, 15px, 0);
  padding-top: pxToVwMobile(26.5px);
  padding-bottom: pxToVwMobile(30px);
  background: $colorBackgroundLight;
}

/* Address block */

.page-footer--address {
  background: $white;
  text-align: center;
  @include fluid(font-size, 14px);
  @include fluid(line-height, 20px);
  @include fluid(border-radius, 20px);
  @include fluidDiff(padding-top, 40px, 80px);
  @include fluidDiff(padding-right, 50px, 50px);
  @include fluidDiff(padding-bottom, 40px, 80px);
  @include fluidDiff(padding-left, 50px, 50px);
  @include fluidDiff(max-width, 320px, 370px);
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: pxToVwMobile(41.5px);

  & > div:not(.page-footer--address-logo) {
    @include fluid(margin-bottom, 20px);
  }

  a:not(.btn) {
    color: $black;
  }
}
.page-footer--address-logo {
  @include fluid(width, 158px);
  margin-left: auto;
  margin-right: auto;
  @include fluidDiff(margin-bottom, 20px, 40px);
}

/* Menus */

.footer-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    @include fluid(margin-bottom, 15px);
    @include fluid(line-height, 25px);
  }
}

/* Newsletter Signup */

.footer-newsletter {
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
  @include fluidDiff(margin-bottom, 50px, 0);

  input[type=email] {
    @include fluidDiff(margin-bottom, 20px, 0);
  }

  label {
    text-align: left;
    @include fluid(font-size, 14px);
    @include fluid(line-height, 20px);
    margin-bottom: pxToVwMobile(23px);
    display: grid;
    grid-template-columns: pxToVwMobile(30px) auto;
    @include media-breakpoint-up(md) {
      grid-template-columns: 30px auto;
    }
  }
}

/* Footer Gallery */

.footer--gallery {


}
.footer--gallery-grid {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  //grid-template-columns: repeat(3, minmax(1fr, 152px));
  //grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(3, minmax(0, 170px));
  grid-column-gap: 32px;
  & > a {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color1, .8);
    transition: opacity ease-in-out .3s;
    opacity: 0;
    display: grid;
    place-content: center
  }
  i {
    color: $white;
    font-size: 30px;
  }
}

/* Bottom Element - Copyright and Conecto */

.footer--bottom {
  border-top: 1px solid $colorBorder;
  @include fluidDiff(padding-top, 15px, 25px);
  @include fluid(font-size, 14px);
  @include fluid(line-height, 20px);

  .logo--flat {
    @include fluid(margin-top, 5px);
  }
}

.copyright {
  opacity: .4;
  text-transform: uppercase;
}

/* ----------------- */

/* Footer Grid & Different Sizes */

.footer--address {
  grid-area: address;
}
.footer--menus {
  grid-area: menus;
}
.footer--widgets {
  grid-area: widgets;
}
.footer--info {
  grid-area: info
}
.footer--grid {
  display: grid;
  grid-template-areas:
    'address'
    'menus'
    'widgets'
    'info';
}

@include media-breakpoint-down(sm) {
  .footer--menus {
    padding-left: pxToVwMobile(30px);
    padding-right: pxToVwMobile(30px);
  }
  .footer-menu {
    padding-bottom: pxToVwMobile(30px);
  }
  .footer--widgets {
    padding-left: pxToVwMobile(30px);
    padding-right: pxToVwMobile(30px);
    padding-bottom: pxToVwMobile(40px);
  }
  .footer--bottom {
    padding-left: pxToVwMobile(30px);
    padding-right: pxToVwMobile(30px);
    text-align: center;
  }
}

@include media-breakpoint-up(md) {
  .page-footer--address {
    margin-bottom: 0;
  }
  .footer--menus {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .footer--grid {
    grid-row-gap: 50px;
  }
  .footer--bottom {
    text-align: right;
  }
}
@include media-breakpoint-up(mdl) {
  // todo: make it beautiful
  /*.footer--grid:not(.is-empty) {
    grid-template-columns: 25% 25% 50%;
    grid-template-areas:
    'address address address'
    'menus menus widgets'
    'info info info';
  }*/
}

@include media-breakpoint-up(lg) {
  .footer--grid {
    grid-template-columns: 400px 1fr 1fr;
    grid-template-areas:
    'address menus menus'
    'address widgets widgets'
    'info info info';
    &.is-empty {
      grid-template-areas:
    'address . .'
    'address menus menus'
    'address . .'
    'info info info';
    }
  }
  .page-footer--address {
    margin: 0;
  }
  .footer-grid {
    &.is-empty {
      .page-footer--address {
        margin: 0 auto;
      }
    }
  }
}

/*@include media-breakpoint-up(lgl) {
  .footer--grid {
    grid-template-columns: 450px 1fr 1fr;
  }
}*/

@include media-breakpoint-up(xll) {
  .footer--grid {
    grid-template-areas:
    'address . .'
    'address menus widgets'
    'address menus widgets'
    'address info info'
    'address . .';
  }
}

@include media-breakpoint-up(xlplus) {
  .page-footer {
    padding-top: 155px;
    padding-bottom: 140px;
  }
  .footer--grid {
    grid-template-columns: 534px 1fr 1fr;
    grid-template-areas:
    'address . .'
    'address menus widgets'
    'address menus widgets'
    'address info info'
    'address . .';
    &.is-empty {
      grid-template-areas:
      'address . .'
      'address menus menus'
      'address menus menus'
      'address info info'
       'address . .'
    }
  }
}

@include media-breakpoint-up(md) {

  /* Newsletter Sign-up */

  .footer--newsletter {
    input[type=checkbox] {
      @include fluid(margin-top, 3px);
    }

    .newsletter-button {
      display: flex;

      button {
        align-self: flex-end;
      }
    }

    form {
      display: grid;
      grid-template-columns: 8fr 2fr;
      @include fluidPlus(row-gap, 20px);
      @include fluidPlus(column-gap, 20px);
      grid-template-areas:
            "input button"
            "label x";
    }

    .newsletter--input {
      grid-area: input;
    }

    .newsletter--button {
      grid-area: button;
    }

    .newsletter--label {
    }
  }
  .newsletter--label {
    grid-area: label;

    label {
      grid-template-columns: pxToVw(30px) auto;
      @include media-breakpoint-up(xl) {
        grid-template-columns: 30px auto;
      }
    }
  }

}