.contentblock--textbox {
  .textbox {
    background: $color1;
    color: $white;
  }
  img, video {
    width: 100%;
    height: auto;
  }
  a {
    color: $white;
  }
  @include media-breakpoint-up(md) {
    .textbox--image {
      position: relative;
      height: 100%;
      img, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .textbox--content-container {
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
    padding-top: pxToVwMobile(40px);
    padding-right: pxToVwMobile(35.73px);
    padding-bottom: pxToVwMobile(40px);
    padding-left: pxToVwMobile(35.73px);
    @include media-breakpoint-up(md) {
      padding: 10% 10% 16% 10%;
    }
  }
  h2, h3 {
    font-weight: $font-weight-boldest;
    margin-bottom: pxToVwMobile(20px);
    @include media-breakpoint-up(md) {
      margin-bottom: 5%;
    }
  }
  h3 {
    @include fluidDiff(font-size, 22px, 31px);
    @include fluidDiff(line-height, 26px, 36px);
  }
  p {
    margin-bottom: pxToVwMobile(20px);
    @include media-breakpoint-up(md) {
      margin-bottom: 5%;
    }
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    @include fluid(margin-bottom, 30px);
    li {
      @include fluid(padding-left, 20px);
      position: relative;
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        background: $white;
        position: absolute;
        left: 0;
        top: 9px;
      }
    }
  }
  a.btn--outline-negative {
    &:hover {
      border-color: $white;
      background: $white;
      color: $color1;
    }
  }
}

@include media-breakpoint-up(md) {
  .textbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    &.textbox--image-right {
      direction: rtl;
      & * {
        direction: ltr;
      }
    }
  }
}