/* Selection */

::selection {
  background: $color2;
  color: $white;
}

/* Basic Containers */

html {
  scroll-behavior: smooth;
}
body {
  background: #f2f2f2;
  @include fluidDiff(font-size, $font-size-base-mobile, $font-size-base);
  @include fluidDiff(line-height, $line-height-base-mobile, $line-height-base);
}

.page-wrap {
  margin: 0 auto;
  background: $white;
}

main[role=main] {
  @include fluidDiff(padding-top, 60px, 100px);
  @include media-breakpoint-up(md) {
    padding-bottom: 55px;
  }
  background-color: #f2f2f2;
}

/* Container */

/* Content widths according to BB:

  - narrow (960 - 1036px - mean value 998px which corresponds to 992px from bootstrap widths)
  - normal (1570px)
  - wide (100%)

  2, 3 and 4 columns are possible

 */

.container {
  max-width: map-get($grid-breakpoints, xlplus);
  margin: 0 auto;
  @include fluidDiff(padding-left, $containerMarginMobile, $containerMarginDesktop);
  @include fluidDiff(padding-right, $containerMarginMobile, $containerMarginDesktop);
}
@include media-breakpoint-up(md) {
  .container {

  }
  .container--narrow {
    max-width: map-get($grid-breakpoints, lg)
  }
  .container--wide {
    max-width: 100%;
    @include fluidDiff(padding-left, $containerMarginMobile, 0);
    @include fluidDiff(padding-right, $containerMarginMobile, 0);
  }
}

/* Columns */

.cols {
  .container {
    @include fluidDiff(padding-left, 0, 0);
    @include fluidDiff(padding-right, 0, 0);
  }
}

@include media-breakpoint-down(md) {
  .cols {
    .col {
      margin-bottom: pxToVwMobile(30px);
    }
  }
}
@include media-breakpoint-up(md) {
  .cols {
    .contentblock {
      &.margin-bottom-tiny,
      &.margin-bottom-small,
      &.margin-bottom-medium,
      &.margin-bottom-large,
      &.margin-bottom-verylarge {
        margin-bottom: $containerMarginDesktop;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cols {
    display: grid;
    grid-column-gap: $containerMarginDesktop;
  }
  .cols--2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols--3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .cols--4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@include media-breakpoint-up(xll) {
  .cols {
    grid-column-gap: $containerMarginDesktop * 2;
  }
}

/* Contentblocks */

.contentblock {

  @include fluidDiff(margin-bottom, 30px, 100px);

  // containerMarginMobile = 15px
  // containerMarginDesktop = 30px
  &.margin-bottom {
    &-none {
      @include fluidDiff(margin-bottom, 0, 0);
    }
    &-tiny {
      @include fluid(margin-bottom, $containerMarginMobile);
    }
    &-small {
      @include fluidDiff(margin-bottom, $containerMarginDesktop, $containerMarginDesktop);
    }
    &-medium {
      @include fluidDiff(margin-bottom, $containerMarginDesktop, $containerMarginDesktop * 2);
    }
    &-large {
      @include fluidDiff(margin-bottom, $containerMarginDesktop, $containerMarginDesktop * 3);
    }
    &-verylarge {
      @include fluidDiff(margin-bottom, $containerMarginDesktop, $containerMarginDesktop * 4);
    }
  }


}

/* Columns */

@include media-breakpoint-up(lg) {
  .columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
  .columns--3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .columns--4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .columns--5 {
    grid-template-columns: repeat(5, 1fr);
  }
}