.contentblock--disruptor {

}
.disruptor--image {
  position: relative;
  figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 28.125%;
    background-color: rgba($color1, .2);
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .centered {
    width: 100%;
    position: absolute;
    z-index: 1;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    margin-bottom: 30px;
    color: $white;
  }
}

@include media-breakpoint-down(md) {
  .disruptor--image {
    figure {
      padding-bottom: 50%;
    }
    h2 {
      font-size: pxToVwMobile(31px);
      line-height: pxToVwMobile(36px);
      margin-bottom: pxToVwMobile(20px);
    }
  }
}