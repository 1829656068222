/* Colors */

$black: #000000;
$white: #FFFFFF;

$color1: #6E110C;
$color1dark: darken($color1, 10%);
$color1darker: darken($color1, 20%);
$color2: #6E110C;
$color3: #C5D9D9;;
$color4: #183459;
$colorText: #333333;
$colorText1: #C4C4C4;
$colorBackground: $white;
$colorBackgroundLight: #F2F2F2;
$colorBorder: rgba(0, 0, 0, 0.1);
$colorButtonHover: $colorText;
$colorTextHover: $white;
$colorButtonOutlineHover: $color1;
$colorButtonOutlineTextHover: $color1;
$colorTextLinkHover: $color1;

$colors: (
        color1: $color1,
        color2: $color2,
        color3: $color3,
        color4: $color4,
        colorText: $colorText,
        colorText1: $colorText1,
        colorBackgroundLight: $colorBackgroundLight,
        colorBorder: $colorBorder,
        colorButtonHover: $colorButtonHover,
        colorTextHover: $colorTextHover,
        colorButtonOutlineHover: $colorButtonOutlineHover,
        colorButtonOutlineTextHover: $colorButtonOutlineTextHover,
        colorTextLinkHover: $colorTextLinkHover
) !default;


$black: rgba(0,0,0,1);
$white: rgba(255,255,255,1);
$grey: rgba(236,237,237,1);
$grey-dark: rgba(197,198,200,1);
$link-color: $color1;
$link-hover-color: $color2;


/* Conecto Modal Variables */

:root {
  --cm-border-color: #69BFAC;
  --cm-font-headline: 'Arial';
  --cm-font-text: 'Times New Roman';
}